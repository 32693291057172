import { API_ENDPOINT_WEB } from "services/api"

export const parseCurrency = (value) => {
    let values = `${(parseFloat(value)||0).toFixed(2)}`.split('.')
    return `R$${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`
}  

export const parsePercentage = (value) => {
    return `${ ( parseFloat(value) || 0 ).toFixed(2) }%`
}  

export const parseCurrencyMoney = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return `${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`.split(',')[0]
}

export const parseCurrencyCents = (value) => {
    let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    return values[1]
}
 
export const parseDatestringBRUS = datestring => {
    const ds = `${ datestring }`?.split('/')
    return `${ ds[2] }-${ ds[1] }-${ ds[0] }`
}

export const parseDatestringUSBR = datestring => {
    const ds = `${ datestring }`?.split('T')?.[0]?.split('-')
    return `${ ds[2] }/${ ds[1] }/${ ds[0] }`
}

export const maskToCurrency = ({ nextState }) => {
    const { value } = nextState || {}
  
    let amountFormatted = value?.replace?.(/\D/g, '')
    amountFormatted = amountFormatted?.replace?.(/^0+/g, '')
  
    if (amountFormatted?.length === 2) {
      return {
        ...nextState,
        value: `R$ ${amountFormatted}`,
        selection: {
          start: amountFormatted.length + 3,
          end: amountFormatted.length + 3
        }
      }
    }
  
    const amountFormattedWithComma = amountFormatted?.replace?.(
      /(?=\d{2})(\d{2})$/,
      ',$1'
    )
    const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1.'
    )
  
    if (amountFormattedWithDot) {
      return {
        ...nextState,
        value: `R$ ${amountFormattedWithDot}`,
        selection: {
          start: amountFormattedWithDot.length + 3,
          end: amountFormattedWithDot.length + 3
        }
      }
    }
  
    return nextState
  }



export const clearVotationId = link => {
  return `${ link }`?.replace(`${API_ENDPOINT_WEB}/dashboard/accreditation/`, '')
}