import React, { useContext, useState } from "react";   
import { useHistory } from "react-router-dom";
 
import { 
    
    DashboardUserProfileContainer,
    
    HeaderMenuContainer,
    HeaderMenuItem,
    HeaderMenuItemText,
    HeaderMenuItemIcon,
    HeaderMenuItemSeparator,
    DashboardMenuContainer
} from "./styled"; 
import { CoreContext } from "context/CoreContext";
import { DoLogout } from "services/authentication";
import { MobileContent } from "ui/styled";

export default function DashboardHeaderMenu({ children }){  
    
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext) 
    const [userOpen, setUserOpen] = useState(false)

    const options = [
        // { title:"Meus dados", action: () => navigate("dashboard/forms/user/data") },
        // { title:"Senha", action: () => navigate("dashboard/forms/user/password") },
        { title:"Sair", action: () => exit() },
    ]


    const sideOptions = [
        { title:"Processos", action: () => navigate("dashboard") },
        { title:"Assembleias", action: () => navigate("dashboard/assemblies") },
    ]


    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    return ( 
        <> 
            <DashboardMenuContainer>
                <DashboardUserProfileContainer onClick={() => setUserOpen(!userOpen)}>
                    { children }
                </DashboardUserProfileContainer>
                {
                    !userOpen ? null :
                        <HeaderMenuContainer>

                            <HeaderMenuItem opened onClick={() => setUserOpen(false)}>
                                <HeaderMenuItemText>{ user?.name }</HeaderMenuItemText>
                                <HeaderMenuItemIcon opened />
                            </HeaderMenuItem>

                            <HeaderMenuItemSeparator />
                            <MobileContent>
                                {
                                    sideOptions?.map((item, key) => 
                                        <HeaderMenuItem key={key} onClick={item.action}>
                                            <HeaderMenuItemText>{ item?.title }</HeaderMenuItemText>
                                            {/* <HeaderMenuItemIcon /> */}
                                        </HeaderMenuItem>
                                    )
                                }
                            </MobileContent>
                            
                            {
                                options?.map((item, key) => 
                                    <HeaderMenuItem key={key} onClick={item.action}>
                                        <HeaderMenuItemText>{ item?.title }</HeaderMenuItemText>
                                        <HeaderMenuItemIcon />
                                    </HeaderMenuItem>
                                )
                            }

                        </HeaderMenuContainer>
                }
            </DashboardMenuContainer>
        </>
    );
}