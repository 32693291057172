import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentCard,
    ContentCardTable,
    ContentCardTableAction,

    StepsContainer,
    StepItem,
    StepItemCircle,
    StepItemCircleDecoration,
    FormSpacer,
    FormSuccessIcon,

    DashboardInfo,
    DashboardInfoItem,
    DashboardInfoItemTitle,
    DashboardInfoItemText,

    DashboardOption,
    DashboardOptionAction,
    DashboardOptionIcon,
    CardCred,
    CardCredHeader,
    RemoveLink,
    CardCredBody,
    FormItem,
    CardCredFooter,
    CardCredFooterItem,
    FormSuccessIconLogo,
    
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col, Container } from "reactstrap";
import Input from "components/Form/Input";

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Load, LoadCenter } from "ui/styled";
import Button from "components/Form/Button";
import PasswordValidation from "components/Form/PasswordValidation";
import Check from "components/Form/Check";
import { ReadByCode } from "services/processes";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils";
import { UpdateMePassword } from "services/me";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { CoreContext } from "context/CoreContext";
import { API_ENDPOINT_WEB } from "services/api";
import { Read } from "services/process-users";
import { Read as ReadSchedules } from "services/process-schedules";
import { optionsClassification, optionsVoteOptions } from "utils/options";
import { parseCurrency } from "utils/parsers";
import { Create, ReadProcess, ReadProcessSchedule } from "services/votings";

import { Update as UpdateProcessUser } from "services/process-users";

export default function DashboardVote(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    
    const params = useParams()
    const { id } = params
    
    const { user, first } = useContext(CoreContext)
    
    const [ searchExpression, setSearchExpression ] = useState("")
    
    const [ success, setSuccess ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ sending, setSending ] = useState(false)
    const [ message, setMessage ] = useState(null)

    const [ step, setStep ] = useState(first ? 1 : 3)

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const [currentProcess, setCurrentProcess] = useState(null)

    const [processUsers, setProcessUsers] = useState([])
    const [processSchedules, setProcessSchedules] = useState([])

    const [selected, setSelected] = useState([ ])
    const [votation, setVotation] = useState(null)
    
    const [massVote, setMassVote] = useState(false)
    const [validating, setValidating] = useState(false)
    
    const [massSelection, setMassSelection] = useState(null)
    const [singleSelection, setSingleSelection] = useState([])
    
    const [votationsDone, setVotationsDone] = useState([])

    const isSelected = item => {
        return selected?.map(m => m?.id)?.includes(item?.id)
    }

    const toggleSelected = item => {
        setSelected( isSelected(item) ? [ ...selected ]?.filter(f => f?.id !== item?.id ) : [ ...selected, item ] )
    }

    const toggleAll = () => {
        setSelected( selected?.length === processUsers?.length ? [] : processUsers )
    }


    const next = () => {
        setStep(step+1)
    }

    const doubleNext = () => {
        setStep(step+2)
    }

    const valid = (verbose = false) => {   

        if(!formValue('password') || !formValue('password').length){ 
            if(verbose){ toast.error('Preencha o campo: Nova senha') ;}
            return false; 
        }  

        if(!formValue('cpassword') || !formValue('cpassword').length){ 
            if(verbose){ toast.error('Preencha o campo: Repita a nova senha') ;}
            return false; 
        }  

        if( formValue('password') !== formValue('cpassword')){ 
            if(verbose){ toast.error('Nova senha e Repita a nova senha devem ser iguais') ;}
            return false; 
        }  

        return true
    } 

    const save = async () => {
        if(!valid(true)){ return ;}
        setLoading(true)
        const result = await UpdateMePassword({
            password:form?.password
        })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            setSuccess(true)
        }
    }

    const steps = [
        { title:"Login" },
        { title:"Alterar senha" },
        { title:"Criar nova senha" },
        { title:"Início da assembleia" },
        { title:"Confirmar credores" },
        { title:"Aguarde a votação" },
        { title:"Votação iniciada" },
        { title:"Votação finalizada" },
    ]

    const validateNext = async () => {
        setValidating(true)
        const result = await validateVotation()

        if(result){
            if(["attorney", "admin"].includes(user?.type)){
                next()
            }else{
                doubleNext()
            }
        }

        setValidating(false)
    }

    const validateVotation = async () => {
        if(id){
            const result = await ReadByCode(`${API_ENDPOINT_WEB}/dashboard/accreditation/${ id }`)
            const normalResult = normalizeStrapiList(result)
            const process = normalResult?.[0]
            setCurrentProcess(process) 

            if(process?.status === "scheduled"){
                setMessage({
                    title:"Assembleia não foi iniciada",
                    text:"Aguarde a assembleia ser iniciada para enviar sua participação",
                })
                setStep(8)
                return false;
            }

            if(process?.status === "finished"){
                setMessage({
                    title:"Assembleia encerrada",
                    text:"Obrigado pela participação",
                })
                setStep(8)
                return false;
            }

            if(process?.id){
                const votes = await ReadProcess(process?.id)
                const users = await Read(process?.id)
                const normalVotes = votes?.data?.length ? normalizeStrapiList(votes) : []

                const schedules = await ReadSchedules(process?.id)

                if(schedules?.data?.length){
                    const normalSchedule = normalizeStrapiList(schedules)
                    const normalProcessSchedule = (normalSchedule || []).map(m => ({ ...m }))
                    setProcessSchedules(normalProcessSchedule)
                }
    
                if(users?.data?.length){
                    const normalResult = normalizeStrapiList(users)
                    const normalProcessUser = (normalResult || []).map(m => {
                        const user_creditor = normalizeStrapiRegister(m?.user_creditor)
                        const represented_users = normalizeStrapiList(m?.represented_users)
                        return {
                            ...m,
                            user: user_creditor,
                            represented_users
                        }
                    })

                    const votationUser = normalProcessUser?.find(f => f?.user?.id === user.id )
                    
                    if( user?.type !== "admin" ){ 

                        const enableToAssembly = (
                            ( user?.type === "creditor" && votationUser?.enabled ) ||
                            ( user?.type === "attorney" && votationUser?.represented_users?.some(f => f.enabled ) )
                        )

                        if( !enableToAssembly ){
                            
                            setMessage({
                                title:"Assembleia indisponível",
                                text: user?.type === "attorney" ? "Seus credores ainda não estão habilitados para a assembleia" : "Você não está habilitado para essa assembleia",
                            })

                            setStep(8)
                            return ;
                        }


                    }

                    if( user?.type !== "attorney" ){ 

                        if(!votationUser){
                            setMessage({
                                title:"Assembleia incorreta",
                                text:"Você não está registrado para essa assembleia",
                            })
                            setStep(8)
                            return false;
                        }
                        
                    }

                    if( ["attorney", "admin"].includes(user?.type) ){

                        const everybody_voted = votationUser?.represented_users?.filter(f => f.enabled)?.filter(f => 
                            normalVotes?.some(m => m?.process_user?.data?.id === f?.id)
                        )?.length === votationUser?.represented_users?.filter(f => f.enabled)?.length
                        

                        console.log("votationUser?.represented_users?.filter(f => f.enabled)?.length", votationUser?.represented_users?.filter(f => f.enabled), normalVotes)

                        if(everybody_voted){
                            setMessage({
                                title:"Votação concluida",
                                text:"Todos os credores já votaram",
                            })
                            setStep(8)
                            return false;
                        }

                    }
                }

                if( normalVotes?.length && user?.type !== "attorney" ){
                    const already_voted = normalVotes?.filter(m => m?.user?.data?.id === user?.id)?.length === schedules?.data?.length
                    if(already_voted){
                        setMessage({
                            title:"Votação concluida",
                            text:"Você já votou, obrigado",
                        })
                        setStep(8)
                        return false;
                    }
                }
                
            }
            return true;
        }
        return false;
    }

    const init = async () => { 
        if(id){
            setLoading(true)
            const result = await ReadByCode(`${API_ENDPOINT_WEB}/dashboard/accreditation/${ id }`)
            const normalResult = normalizeStrapiList(result)
            const process = normalResult?.[0]
            setCurrentProcess(process) 
            
            if(process?.id){ 
                const users = await Read(process?.id) 

                const schedules = await ReadSchedules(process?.id) 
    
                if(schedules?.data?.length){
                    const normalSchedule = normalizeStrapiList(schedules)
                    const normalProcessSchedule = (normalSchedule || []).map(m => { 
                        return {  ...m  }
                    }) 
                    setProcessSchedules(normalProcessSchedule)
                }
                
    
                if(users?.data?.length){
                    const normalResult = normalizeStrapiList(users)

                    const normalProcessUser = (normalResult || []).map(m => {
                        const user_creditor = normalizeStrapiRegister(m?.user_creditor)
                        const represented_users = normalizeStrapiList(m?.represented_users)
                        return {
                            ...m,
                            user: user_creditor,
                            represented_users
                        }
                    })

                    const votationUser = normalProcessUser?.find(f => f?.user?.id === user.id )

                    if( user?.type !== "attorney" ){ 
                        setSelected([
                            { ...votationUser }
                        ])
                    }

                    if( ["attorney", "admin"].includes(user?.type) ){

                        console.log("votationUser?.represented_users", normalProcessUser, votationUser?.represented_users)

                        setProcessUsers(normalProcessUser?.filter(f => votationUser?.represented_users?.map(m => m?.id)?.includes(f.id) ))
                    }
                }
                
            }

            setLoading(false)
        }
    }

    const validVotes = (verbose = false) => {  

        if( massVote && !massSelection ){ 
            if(verbose){ toast.error('Selecione o voto para continuar') ;}
            return false; 
        }  

        if( !massVote && singleSelection?.length !== selected?.length ){ 
            if(verbose){ toast.error('Selecione o voto de todos os credores para continuar') ;}
            return false; 
        }  

        return true
    } 

    const saveVote = async (uu, ky) => {
        
        const vote = massVote ? massSelection?.ref : singleSelection[ky]?.ref

        const payload = {
            title: votation?.title,
            process: currentProcess?.id,
            user: uu?.user?.id,
            process_user: uu?.id,
            vote,
            process_schedule: votation?.id
        }

        // console.log("vote payload", payload)

        await UpdateProcessUser({ data: { voted:true }}, uu?.id)

        return await Create({ data: payload })
    }

    const confirmVotes = async () => {
        if(!validVotes(true)){ return ;}
        
        if(!sending){
            setSending(true)
            const promises = selected?.map(saveVote)
            const results = await Promise.all(promises)
            setSending(false)
    
            console.log("results", results)
    
            if( !(results?.filter(f => f?.data?.id)?.length === selected?.length) ){
                toast.error("Erro ao enviar votos, tente novamente mais tarde");
                return;
            }
            
            const nvd = [ ...votationsDone, votation ]
            setVotationsDone(nvd)
            setVotation(null)
            setMassVote(false)
            setMassSelection(null)
            setSingleSelection([])
            
            if(nvd?.length === processSchedules?.filter(f => f.status === "running")?.length){
                // compled
                next()
            } else {
                setStep(5)
            }
        }
    }

    const selectSingleVote = (item, ref) => {
        let nvotes = [ ...singleSelection ]
        nvotes[ref] = item
        setSingleSelection([ ...nvotes ])
    }
    
    useEffect(() => { init() ;}, [id])

    return ( 
        <>
            <ContainerAuthenticated noside> 
                <Container>
                    {
                        loading ? 
                            <LoadCenter>
                                <Load />
                            </LoadCenter>
                        : <>
                                <Row>
                                    <Col> 

                                        {
                                            !(step === 1) ? null : 
                                            <ContentCardTable>
                                                <DashboardTitle> Olá, { user?.name } </DashboardTitle> 
                                                <DashboardText> Deseja alterar sua senha? </DashboardText> 

                                                <Button primary onClick={doubleNext}>Não alterar</Button>
                                                <Button outline secondary onClick={next}>Alterar senha</Button>
                                            </ContentCardTable>
                                        }

                                        {
                                            !(step === 2) ? null : 
                                            <ContentCardTable>
                                                {
                                                    success ? <>
                                                        <LoadCenter>
                                                            <FormSuccessIcon />
                                                        </LoadCenter>
                                                        <DashboardTitle centred> Crie sua nova senha de acesso </DashboardTitle> 
                                                        <DashboardText centred> Digite uma nova senha para sua conta </DashboardText> 
                                                        <FormSpacer />
                                                        <Button primary onClick={next}>Fechar e ir para página do processo</Button>
                                                    </> : <>

                                                        <DashboardTitle> Crie sua nova senha de acesso </DashboardTitle> 
                                                        <DashboardText> Digite uma nova senha para sua conta </DashboardText> 

                                                        <FormSpacer />
                                                        <Input placeholder="Nova senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />   
                                                        <FormSpacer />
                                                        <Input placeholder="Repita a nova senha" type="password" value={formValue('cpassword')} onChange={e => changeForm(e.target.value, 'cpassword')} />   
                                                        <FormSpacer />

                                                        <PasswordValidation password={form?.password} />
                                                        <Button primary loading={loading} onClick={save}>Salvar</Button>
                                                    </>
                                                }
                                            </ContentCardTable>
                                        }


                                        {
                                            !(step === 3) ? null : 
                                            <ContentCardTable>
                                                <DashboardTitle> Processo </DashboardTitle>  
                                                
                                                <DashboardInfo>
                                                    <DashboardInfoItem>
                                                        <DashboardInfoItemTitle>{ currentProcess?.company_name }</DashboardInfoItemTitle>
                                                        <DashboardInfoItemText>{ currentProcess?.judicial_administrator }</DashboardInfoItemText>
                                                    </DashboardInfoItem>
                                                    <DashboardInfoItem />

                                                    <DashboardInfoItem>
                                                        <DashboardInfoItemTitle>Número do Processo</DashboardInfoItemTitle>
                                                        <DashboardInfoItemText>{ currentProcess?.number_process }</DashboardInfoItemText>
                                                    </DashboardInfoItem>
                                                    <DashboardInfoItem>
                                                        <DashboardInfoItemTitle>Data / Hora</DashboardInfoItemTitle>
                                                        <DashboardInfoItemText>{ moment(currentProcess?.datetime).format("L hh:mm") }</DashboardInfoItemText>
                                                    </DashboardInfoItem>
                                                    <DashboardInfoItem>
                                                        <DashboardInfoItemTitle>Início do credenciamento</DashboardInfoItemTitle>
                                                        <DashboardInfoItemText>{ moment(currentProcess?.start_accreditation).format("L hh:mm") }</DashboardInfoItemText>
                                                    </DashboardInfoItem>
                                                    <DashboardInfoItem>
                                                        <DashboardInfoItemTitle>Início da AGC</DashboardInfoItemTitle>
                                                        <DashboardInfoItemText>{ moment(currentProcess?.start_agc).format("L hh:mm") }</DashboardInfoItemText>
                                                    </DashboardInfoItem>

                                                </DashboardInfo>
                                                
                                                {
                                                    !(currentProcess?.files?.data || [])?.length ? null : <>
                                                        <FormSpacer />
                                                        <DashboardTitle> Documentos do processo </DashboardTitle> 
                                                        {
                                                            (currentProcess?.files?.data || [])?.map((item, key) => 
                                                                <DashboardOption key={key} onClick={() => window.open(`${ parseStrapiImage(item?.attributes?.url) }`, "new")}>
                                                                    { item?.attributes?.name }
                                                                    <DashboardOptionAction>
                                                                        Visualizar
                                                                        <DashboardOptionIcon />
                                                                    </DashboardOptionAction>
                                                                </DashboardOption>
                                                            )
                                                        }
                                                    </>
                                                }
                                                <FormSpacer />
                                                <Button primary loading={ validating } onClick={ validateNext}>Iniciar</Button>
                                            </ContentCardTable>
                                        }

                                        {
                                            !(step === 4) ? null : 
                                            <ContentCardTable>
                                                <DashboardTitle> Representação de credores </DashboardTitle> 
                                                <DashboardText> Selecione e confirme os credores que serão representados por você na assembleia. </DashboardText> 
                                                
                                                <FormSpacer />
                                                <Check label="Selecionar todos" checked={processUsers?.length === selected?.length} onChange={() => toggleAll()}  />
                                                
                                                {
                                                    processUsers?.filter(f => f.enabled && !f.voted).map((item, key) => 
                                                        <CardCred key={key}>
                                                            <CardCredHeader>
                                                                <Check label={ item?.user?.name } checked={isSelected(item)} onChange={() => toggleSelected(item)} />
                                                                <RemoveLink onClick={() => setProcessUsers( [ ...processUsers?.filter((f, k) => k!==key) ] )}> Excluir </RemoveLink>
                                                            </CardCredHeader>
                                                            <CardCredBody>
                                                                <DashboardInfoItem>
                                                                    <DashboardInfoItemTitle>Credor</DashboardInfoItemTitle>
                                                                    <DashboardInfoItemText>{ item?.user?.name }</DashboardInfoItemText>
                                                                </DashboardInfoItem>
                                                                <DashboardInfoItem>
                                                                    <DashboardInfoItemTitle>{ currentProcess?.company_name }</DashboardInfoItemTitle>
                                                                    <DashboardInfoItemText>{ currentProcess?.judicial_administrator }</DashboardInfoItemText>
                                                                </DashboardInfoItem>
                                                            </CardCredBody>
                                                        </CardCred>
                                                    )
                                                }

                                                <FormSpacer />
                                                <Button primary onClick={!selected?.length ? () => toast.error("Selecione o credor para continuar") : next}>Confirmar</Button>
                                            </ContentCardTable>
                                        }

                                        {
                                            !(step === 5) || votation ? null : 
                                            <ContentCardTable>
                                                <DashboardTitle> Votações em pauta </DashboardTitle> 
                                                <DashboardText> Selecione a pauta a ser votada </DashboardText> 
                                                {
                                                    processSchedules?.filter(f => !votationsDone?.map(m => m.id)?.includes(f.id))?.filter(f => f.status === "running").map((item, key) => 
                                                        <CardCred key={key}>
                                                            <CardCredHeader>
                                                                <DashboardInfoItemTitle>{ item?.title }</DashboardInfoItemTitle>
                                                                <FormItem>
                                                                    <Button onClick={() => { setVotation(item); next() }} nospace outline secondary>Iniciar votação</Button>
                                                                </FormItem>
                                                            </CardCredHeader>
                                                        </CardCred>
                                                    )
                                                }

                                                <FormSpacer />
                                            </ContentCardTable>
                                        }


                                        {
                                            !(step === 6) || !votation ? null : 
                                            <ContentCardTable>
                                                
                                                <DashboardTitle> Votação </DashboardTitle> 
                                                <DashboardText> { votation?.title } </DashboardText> 
                                                
                                                <FormSpacer />
                                                <DashboardOption onClick={() => setMassVote(!massVote)}>
                                                    { massVote ? "Voto único" : "Votos em massa"}
                                                    <DashboardOptionAction>
                                                        <DashboardOptionIcon />
                                                    </DashboardOptionAction>
                                                </DashboardOption>
                                                
                                                {
                                                    selected.map((item, key) => 
                                                        <CardCred key={key}>
                                                            <CardCredHeader>
                                                                { item?.user?.name }
                                                            </CardCredHeader>
                                                            <CardCredBody>
                                                                <DashboardInfoItem>
                                                                    <DashboardInfoItemTitle>Classe</DashboardInfoItemTitle>
                                                                    <DashboardInfoItemText>{ optionsClassification?.find(f => f.ref === item?.user?.class)?.title }</DashboardInfoItemText>
                                                                </DashboardInfoItem>
                                                                <DashboardInfoItem>
                                                                    <DashboardInfoItemTitle>Crédito</DashboardInfoItemTitle>
                                                                    <DashboardInfoItemText>{ parseCurrency(item?.value) }</DashboardInfoItemText>
                                                                </DashboardInfoItem>
                                                            </CardCredBody>
                                                            {
                                                                massVote ? null :
                                                                <CardCredFooter>
                                                                    {
                                                                        optionsVoteOptions.map((it, ky) => 
                                                                            <CardCredFooterItem active={singleSelection?.filter(f => f?.user?.id === item?.user?.id)?.map(m => m?.id)?.includes(it?.id)} status={it?.title} key={ky} onClick={() => selectSingleVote({ ...it, user: item?.user }, key)}>
                                                                                { it?.title }
                                                                            </CardCredFooterItem>
                                                                        )
                                                                    }

                                                                </CardCredFooter>
                                                            }
                                                        </CardCred>
                                                    )
                                                }

                                                <FormSpacer />

                                                {
                                                    massVote ? <>
                                                        <CardCredFooter>
                                                            {
                                                                optionsVoteOptions.map((item, key) => 
                                                                    <CardCredFooterItem active={massSelection?.id === item?.id} status={item?.title} key={key} onClick={() => setMassSelection(item)}>
                                                                        { item?.title }
                                                                    </CardCredFooterItem>
                                                                )
                                                            }
                                                        </CardCredFooter>
                                                        <Button secondary loading={sending} onClick={confirmVotes}>Confirmar a votação</Button>
                                                    </> : 
                                                    <Button primary loading={sending} onClick={confirmVotes}>Confirmar a votação</Button>
                                                }

                                            </ContentCardTable>
                                        }

                                        {
                                            !(step === 7) ? null : 
                                            <ContentCardTable>

                                                <LoadCenter>
                                                    <FormSuccessIcon />
                                                </LoadCenter>
                                                <DashboardText centred> Plano de recuperação judicial </DashboardText> 
                                                <DashboardTitle centred> Votação realizada com sucesso! </DashboardTitle> 
                                                <FormSpacer />
                                                
                                                {/* <Button primary onClick={next}>Fechar e ir para página do processo</Button> */}
                                                
                                            </ContentCardTable>
                                        }
                                    
                                        {
                                            !(step === 8) ? null : 
                                            <ContentCardTable>

                                                <LoadCenter>
                                                    <FormSuccessIconLogo />
                                                </LoadCenter>

                                                <DashboardText centred> { message.title } </DashboardText> 
                                                <DashboardTitle centred> { message.text } </DashboardTitle> 

                                                <FormSpacer />
                                            </ContentCardTable>
                                        }

                                    </Col>
                                    {
                                        message ? null : 
                                        <Col>
                                            <StepsContainer>
                                                {
                                                    steps?.map((item, key) => 
                                                        <StepItem key={key} future={key > step} onClick={((key === 0) || (key > step) || (step+1 === steps?.length)) ? null : () => setStep(key)}>
                                                            <StepItemCircle active={key < step} future={key > step}>
                                                                { key >= step ? (key+1) : (key < step ? <StepItemCircleDecoration /> : null ) }
                                                            </StepItemCircle>
                                                            { item?.title }
                                                        </StepItem>
                                                    )
                                                }
                                            </StepsContainer>
                                        </Col>
                                    } 
                                </Row>
                        </>
                    }
                </Container>
            </ContainerAuthenticated> 
        </>
    );
}