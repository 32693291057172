import styled from 'styled-components'   
 




export const DashboardMenuContainer = styled.div.attrs({ 
})` 
    margin: 0 0 0 auto;
`;

export const DashboardUserProfileContainer = styled.div.attrs({ 
})` 
    display: flex;
    align-items: center; 
    gap: 12px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;










export const HeaderMenuContainer = styled.div.attrs({ 
})` 
    padding: 12px;
    border-radius: 8px;
    background: ${ p => p.theme.palette.colors.backgroundgrey };
    box-shadow: 0px 3px 6px ${ p => p.theme.palette.colors.shadow };

    position: absolute;
    z-index:1;
    right: 10px;
    margin-top: 12px;


    width: 100%;
    max-width: 320px;

    z-index:2;

`;
export const HeaderMenuItem = styled.div.attrs({ 
})` 
    padding: 12px;
    border-radius: 8px;
    background: ${ p => p.theme.palette.colors.white };
    color: ${ p => p.theme.palette.colors.text };
    margin-bottom: 6px;
    display: flex;
    cursor: pointer;
    transition: all .3s ease;
    ${
        p => p.opened ? `
            background: ${ p.theme.palette.primary.main };
            color: ${ p.theme.palette.colors.white };
        ` : `
        `
    }
    &:hover{
        transform: scale(1.01);
    }
`;
export const HeaderMenuItemText = styled.div.attrs({ 
})` 
    flex:1;
`;
export const HeaderMenuItemIcon = styled.img.attrs( p => ({ 
    src: p.opened ? '/icons/chevron-white.svg' : '/icons/chevron.svg'
}))` 
    ${
        p => p.opened ? `
            transform: rotate(180deg);
        ` : `
            transform: rotate(270deg);
        `
    }
`;
export const HeaderMenuItemSeparator = styled.div.attrs({ 
})` 
    margin: 12px 0px;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${ p => p.theme.palette.colors.lightshadow };
`;