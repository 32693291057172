import React, { useContext } from "react";
import ModalWrapper from "./Wrapper";
import { ThemedComponent } from "ui/theme";

import { CoreContext } from "context/CoreContext";
import ModalConfirm from "./Confirm";
import ModalSuccess from "./Success";
import ModalProcessSchedule from "./ProcessSchedule";
// import ModalOptIn from "./OptIn";


export default function ModalController(){
    const { modal } = useContext(CoreContext)
    return (
        <>
            <ThemedComponent>
                { modal?.type === 'confirm' ? <ModalConfirm /> : null }
                { modal?.type === 'success' ? <ModalSuccess /> : null }
                { modal?.type === 'process-schedule' ? <ModalProcessSchedule /> : null }
            </ThemedComponent>
        </>
    )
}