import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 14px;
    line-height: 24px;
    color: ${ props => props.theme.palette.colors.text };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;

export const ContentCard = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };

    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ContentCardItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 300px;

    ${
        p => p.medium ? `
            max-width: 200px;
        ` : ``
    }
    
    ${
        p => p.small ? `
            max-width: 120px;
        ` : ``
    }
`;

export const ContentCardTable = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };
    margin: 12px 0 0;
`;

export const ContentCardActions = styled.div.attrs({ 
})`
    border-left: 1px solid ${ props => props.theme.palette.colors.shadow };

    display: flex;
    flex-direction: column;
    padding: 0 12px;
    gap: 12px;
    width: 100%;
    max-width: 200px;

    margin: 0 0 0 auto;
`;




export const HeaderRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: flex-start;
    gap: 12px; 
    margin-bottom: 24px;
`;

export const HeaderContent = styled.div.attrs({ 
})`
`;

export const HeaderBack = styled.img.attrs({ 
    src:'/icons/circle-back.svg'
})`
    cursor: pointer;
    transition: all .3s ease;

    &:hover{
        transform: scale(1.05);
    }
`;







export const HeaderActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 12px;
`;

export const LabelCredential = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: ${ props => props.theme.palette.colors.backgroundcard };
    max-height: 45px;
`;

export const LabelCredentialText = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const HeaderActionItem = styled.div.attrs({ 
})`
    border-left: 1px solid ${ props => props.theme.palette.colors.shadow };
    padding: 0 0 0 12px;
`;

export const ButtonButtonIconPlay = styled.img.attrs({ 
    src:'/icons/play.svg'
})`
`;



export const ContentTabs = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    padding: 0 0 16px;
`;

export const TabItem = styled.div.attrs({ 
})`
    padding: 21px 12px;
    color: ${ props => props.theme.palette.colors.text };
    ${
        p => p.active ? `
            color: ${ p.theme.palette.primary.main };
            border-bottom: 4px solid ${ p.theme.palette.primary.main };
        ` : ``
    }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const TabItemText = styled.div.attrs({ 
})`
`;
                        



export const CardVote = styled.div.attrs({ 
})`
    padding: 16px;
    background: ${ props => props.theme.palette.colors.white };
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
`;
export const CardVoteTitle = styled.div.attrs({ 
})`
    font-size: 16px;
    font-weight: 600;
    color: ${ props => props.theme.palette.primary.main };
`;
export const CardVoteActions = styled.div.attrs({ 
})`
    display: flex; 
    align-items: center;
    gap: 12px;

    font-size: 14px;
    color: ${ props => props.theme.palette.colors.text };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;
export const CardVoteActionsIcon = styled.img.attrs({ 
    src: '/icons/chevron.svg'
})`
    ${
        p => p.open ? `
            transform: rotate(180deg);
        ` : ``
    }
`;


export const CardVoteActionsContainer = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 12px;
`;

export const CardVoteAction = styled.div.attrs({ 
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;