import React from "react";  

import { useHistory } from 'react-router-dom';

import {  

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuHeaderUserContent,
    DashboardMenuHeaderUserImage, 

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,
    
    DashboardVersionContent,
    DashboardVersionText

} from "./styled"; 

import Button from "components/Form/Button";
import { DoLogout } from "services/authentication";

export default function DashboardSide({ page = 0 }){ 
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    const options = [
        { title:"Processos", page:"dashboard" },
        { title:"Assembleias", page:"dashboard/assemblies" },
    ]

    return ( 
        <>    
                    <DashboardMenu> 
                        {/* <DashboardMenuHeader onClick={() => setOpened(false)}>
                            <DashboardMenuHeaderIcon src={'/icons/close-white.svg'} />
                            fechar
                        </DashboardMenuHeader>  */}
                        <DashboardMenuHeaderUserContent>
                            <DashboardMenuHeaderUserImage /> 
                        </DashboardMenuHeaderUserContent> 

                        <DashboardMenuContent>
                            {/* <DashboardMenuOption onClick={() => navigate('dashboard/me')}> 
                                Meu Perfil
                            </DashboardMenuOption> */}
                            {
                                options?.map((item, key) => 
                                    <DashboardMenuOption key={key} active={key === page} onClick={() => navigate(item?.page)}> 
                                        { item?.title }
                                    </DashboardMenuOption>
                                )
                            }
                        </DashboardMenuContent>
{/*                         
                        <DashboardMenuFooter>
                            <Button primary outline onClick={exit} centred>
                                Sair
                            </Button>   
                            <DashboardVersionContent>
                                <DashboardVersionText>1.0.0</DashboardVersionText>
                                <DashboardVersionText>1.10.1.101</DashboardVersionText>
                            </DashboardVersionContent>
                        </DashboardMenuFooter> */}

                    </DashboardMenu> 
        </>
    );
}