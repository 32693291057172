import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Landpage from 'screens/Landpage' 
import NotFound from 'screens/NotFound' 

import Login from 'screens/Authentication/Login' 
import Register from 'screens/Authentication/Register' 
import Forgot from 'screens/Authentication/Forgot' 
import CreatePassword from 'screens/Authentication/CreatePassword' 

import DashboardHome from 'screens/Dashboard/Home' 
import DashboardMe from 'screens/Dashboard/Me' 
import DashboardAssemblies from "screens/Dashboard/Assemblies";
import DashboardAssemblyDetails from "screens/Dashboard/AssemblyDetails";
import DashboardCreateProcess from "screens/Dashboard/CreateProcess";
import DashboardCreateCreditor from "screens/Dashboard/CreateCreditor";
import Accreditation from "screens/Dashboard/Accreditation";
import DashboardVote from "screens/Dashboard/Vote";

export default function AppRouter() {
    return (
      <Router>  
        <div id="global-wrapper">
          <Switch>
            <Route path="/" exact> <Login /> </Route> 
            
            <Route path="/login" exact> <Login /> </Route> 
            <Route path="/login/:id" exact> <Login /> </Route> 
            <Route path="/login/:id/:email" exact> <Login /> </Route> 
            <Route path="/register" exact> <Register /> </Route> 
            <Route path="/forgot" exact> <Forgot /> </Route> 
            <Route path="/create-password" exact> <CreatePassword /> </Route> 
            
            <Route path="/dashboard" exact> <DashboardHome /> </Route> 
            <Route path="/dashboard/assemblies" exact> <DashboardAssemblies /> </Route> 
            <Route path="/dashboard/assembly/details/:id" exact> <DashboardAssemblyDetails /> </Route> 
            
            <Route path="/dashboard/process/create" exact> <DashboardCreateProcess /> </Route> 
            <Route path="/dashboard/process/create/:id" exact> <DashboardCreateProcess /> </Route> 

            <Route path="/dashboard/creditor/create/:processId" exact> <DashboardCreateCreditor /> </Route> 
            <Route path="/dashboard/creditor/create/:processId/:processUserId/:id" exact> <DashboardCreateCreditor /> </Route> 
            
            <Route path="/dashboard/Me" exact> <DashboardMe /> </Route> 
            
            <Route path="/dashboard/accreditation" exact> <Accreditation /> </Route> 
            <Route path="/dashboard/accreditation/:id" exact> <Accreditation /> </Route> 
            <Route path="/dashboard/accreditation/:id/:mode" exact> <Accreditation /> </Route> 
            <Route path="/dashboard/accreditation/:id/:mode/:type" exact> <Accreditation /> </Route> 
            
            
            {/* <Route path="/dashboard/vote" exact> <DashboardVote /> </Route>  */}
            <Route path="/dashboard/vote/:id" exact> <DashboardVote /> </Route> 

            <Route path="*" exact> <NotFound /> </Route>
          </Switch>
        </div>
      </Router>
    );
}