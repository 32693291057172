import styledCmp from 'styled-components'  

import { styled } from '@mui/material/styles'; 
// import Input from '@mui/material/Input'; // standard (material)
// import Input from '@mui/material/FilledInput'; 
import Input from '@mui/material/OutlinedInput';  

import TextField from '@mui/material/TextField';

export const MaterialInput = styled(Input)(({ theme }) => ({  
    background: theme.palette.colors.white,
    fontSize: 16
}));

export const InputIcon = styledCmp.img.attrs({ 
})`
`;

export const StyledTextField = styledCmp(TextField).attrs({ 
    variant: "outlined"
})`
    background: ${ p => p.theme.palette.colors.white};
    width: 100%;
`;