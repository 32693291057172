import styled from 'styled-components'  
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: bold;
    font-size: 20px; 

    margin: 10px 0 10px;
    text-align: center;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };

    margin-bottom: 30px;
    text-align: center;
`;
 
export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
`; 
 
export const SuccessIcon = styled.img.attrs({ 
    src:'/icons/circle-success.svg'
})`
    margin: 20px auto;
`; 