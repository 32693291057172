import styled from 'styled-components'  
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.primary }; 
    font-weight: bold;
    font-size: 20px; 

    margin: 10px 0 10px;
    ${ p=> p.centred ? `
        text-align: center;
    ` : ``}
    ${ p=> p.spaced ? `
        margin: 20px 0 10px;
    ` : ``}
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.text };

    margin-bottom: 30px;
    ${ p=> p.centred ? `
        text-align: center;
    ` : ``}
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
    ${
        p => p.small ? `
            margin-top: 10px;
        
        ` : ``
    }
`;

export const RegisterForgot = styled.div.attrs({ 
})`           
    margin-top: 40px;
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };
    display: flex;
`;

export const ForgotLink = styled.div.attrs({ 
})`           
    margin-left: 6px;
    color: ${ props => props.theme.palette.primary.main };
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({ 
})`           
    margin: 30px 0 0px;
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 15px;
    font-weight: bold;
`;

export const ContentTerms = styled.div.attrs({ 
})`           
    padding: 16px 8px 0;
    background: ${ props => props.theme.palette.colors.white };
    font-size: 15px;
    display: flex;

    cursor: pointer;

    p{
        marign: 0;
        padding: 0;
    }
    b{
        color: ${ props => props.theme.palette.colors.primary };
    }
`;






export const UploadTitle = styled.div.attrs({ 
})`
`;
export const UploadContent = styled.div.attrs({ 
})`
    display: flex;
    margin-top: 12px;
    gap: 12px;
    padding: 12px 16px;
    border-radius: 4px;
    align-items: center;
    background: ${ props => props.theme.palette.colors.white };
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
    margin-bottom: 12px;
`;
export const UploadContentIcon = styled.img.attrs({ 
    src:'/icons/upload.svg'
})`
`;


export const SuccessIcon = styled.img.attrs({ 
    src:'/icons/circle-success.svg'
})`
`;





export const SearchTitle = styled.div.attrs({ 
})`
`;


export const SuccessContent = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
`;