import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;

export const ContentCard = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };

    display: flex;
    gap: 12px;
`;

export const ContentCardItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 300px;

    ${
        p => p.medium ? `
            max-width: 200px;
        ` : ``
    }
    
    ${
        p => p.small ? `
            max-width: 120px;
        ` : ``
    }
`;


export const ContentCardTable = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };
    margin: 12px 0 0;
`;
