import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 18px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
    ${
        props => props.nomargin ? `
            margin: 0;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 14px;
    line-height: 24px;
    color: ${ props => props.theme.palette.colors.text };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;

export const ContentCard = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };

    display: flex;
    align-items: center;
    gap: 12px;

    flex-wrap: wrap;
`;

export const ContentCardItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 300px;

    ${
        p => p.medium ? `
            max-width: 200px;
        ` : ``
    }
    
    ${
        p => p.small ? `
            max-width: 120px;
        ` : ``
    }
`;

export const ContentCardTable = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };
    margin: 12px 0 0;
`;

export const ContentCardActions = styled.div.attrs({ 
})`
    border-left: 1px solid ${ props => props.theme.palette.colors.shadow };

    display: flex;
    flex-direction: column;
    padding: 0 12px;
    gap: 12px;
    width: 100%;
    max-width: 200px;

    margin: 0 0 0 auto;
`;




export const HeaderRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center; 
    gap: 12px; 
    margin-bottom: 24px;
    @media(max-width: 991px){
        flex-wrap: wrap;
    }
`;

export const HeaderContent = styled.div.attrs({ 
})`
`;

export const HeaderBack = styled.img.attrs({ 
    src:'/icons/circle-back.svg'
})`
    cursor: pointer;
    transition: all .3s ease;

    &:hover{
        transform: scale(1.05);
    }
`;







export const HeaderActions = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 12px;
    flex-wrap: wrap;
`;

export const LabelCredential = styled.div.attrs({ 
})`  
    padding: 16px;
    background: ${ props => props.theme.palette.colors.backgroundcard };
    min-height: 45px;
    @media(max-width: 991px){
        display: none;
    }
`;

export const LabelCredentialText = styled.div.attrs({ 
})`
    font-size: 16px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const HeaderActionItem = styled.div.attrs({ 
})`
    border-left: 1px solid ${ props => props.theme.palette.colors.shadow };
    padding: 0 0 0 12px;
`;

export const ButtonButtonIconPlay = styled.img.attrs({ 
    src:'/icons/play.svg'
})`
    margin: 0 6px 0 0;
`;

export const ButtonButtonIconCopy = styled.img.attrs({ 
    src:'/icons/copy.svg'
})`
`;

export const ButtonButtonIconPdf = styled.img.attrs({ 
    src:'/icons/pdf.svg'
})`
    margin: 0 6px 0 0;
`;



export const ContentTabs = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    padding: 0 0 16px;
`;

export const TabItem = styled.div.attrs({ 
})`
    padding: 21px 12px;
    color: ${ props => props.theme.palette.colors.text };
    ${
        p => p.active ? `
            color: ${ p.theme.palette.primary.main };
            border-bottom: 4px solid ${ p.theme.palette.primary.main };
        ` : ``
    }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const TabItemText = styled.div.attrs({ 
})`
`;
                        



export const CardVote = styled.div.attrs({ 
})`
    padding: 16px;
    background: ${ props => props.theme.palette.colors.white };
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
`;
export const CardVoteTitle = styled.div.attrs({ 
})`
    font-size: 16px;
    font-weight: 600;
    color: ${ props => props.theme.palette.primary.main };
`;
export const CardVoteActions = styled.div.attrs({ 
})`
    display: flex; 
    align-items: center;
    gap: 12px;

    font-size: 14px;
    color: ${ props => props.theme.palette.colors.text };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;
export const CardVoteActionsIcon = styled.img.attrs({ 
    src: '/icons/chevron.svg'
})`
    ${
        p => p.open ? `
            transform: rotate(180deg);
        ` : ``
    }
`;


export const CardVoteActionsContainer = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 12px;
`;

export const CardVoteAction = styled.div.attrs({ 
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const RowResult = styled.div.attrs({ 
})`
    display: flex;
`;

export const RowResultScroll = styled.div.attrs({ 
})`
    width: 100%;
    overflow: auto;
    flex:1;
`;

export const RowResultItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 200px;
    margin: 0 1px;
`;

export const RowResultScrollContainer = styled.div.attrs({ 
})`
    min-width: 100%;
    margin: 0 1px;
`;

export const RowResultScrollContent = styled.div.attrs({ 
})`
    width: 100%;
    display: flex;
`;

export const ToggleItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 40px;
    margin: 0 0 0 auto;
`;

export const ProcuratorContent = styled.div.attrs({ 
})`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;

export const ChangeOption = styled.div.attrs({ 
})` 
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.red };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;





export const RowCenter = styled.div.attrs({ 
})`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const RowCenterItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 120px;
`;
export const ButtonIconAdd = styled.img.attrs({ 
    src:'/icons/plus.svg',
})`
    margin: 0 5px 0 0;
`;








export const ContentRowUpload = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const ContentRowUploadUp = styled.div.attrs({ 
})`
    width: 100%;
`;
export const ContentUpload = styled.div.attrs({ 
})`
    flex:1;
    border-radius:4px;
    background: ${ props => props.theme.palette.colors.white };
    height: 54px;
    padding: 12px;
    gap: 12px;

    display: flex;
    align-items: center;
    border: 1px solid ${ props => props.theme.palette.colors.lightshadow };
`;
export const ContentUploadIcon = styled.img.attrs({ 
    src:'/icons/upload.svg'
})`
`;
export const ContentRemove = styled.div.attrs({ 
})`
    padding: 10px;
    border-radius:4px;
    border: 1px solid ${ props => props.theme.palette.colors.shadow };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        box-shadow: 0px 3px 6px ${ props => props.theme.palette.colors.lightshadow };
    }
`;
export const ContentRemoveIcon = styled.img.attrs({ 
    src:'/icons/trash.svg'
})`
`;
export const ContentAddIcon = styled.img.attrs({ 
    src:'/icons/plus.svg'
})`
    margin: 0 6px 0 0;
`;


export const ContentRowGap = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: flex-end;
`;

export const ContentRowGapItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 320px;
    ${
        p => p.small ? `
            max-width: 220px;
        ` : ``
    }
`;