import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { StyledTextField } from './styled';

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString    
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$ "
      />
    );
  },
);

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CurrencyInput({ onChange, value, placeholder }) {

  return ( 
      <StyledTextField
        label={placeholder}
        value={value}
        onChange={onChange} 
        InputProps={{ inputComponent: NumericFormatCustom }}
      /> 
  );
}
