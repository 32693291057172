import React, { useContext, useRef, useState } from "react";

import { ModalCard, ModalHeader, ModalHeaderActions, ModalHeaderIconClose, ModalHeaderTitle } from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function ModalConfirm(){

    const { modal, setModal } = useContext(CoreContext)
    
    const close = () => {
        setModal(null)
    }
    
    const confirm = () => {
        if( typeof modal?.confirm === "function"){
            modal?.confirm()
        }
        close()
    }
    
    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>{ modal?.title }</ModalHeaderTitle>
                        <ModalHeaderIconClose onClick={close} />
                    </ModalHeader>
                    <ModalHeaderActions>
                        <Button primary outline onClick={close}>Cancelar</Button>
                        <Button primary onClick={confirm}>Sim</Button>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}