import React, { useContext, useRef, useState } from "react";

import { ModalBody, ModalCard, ModalHeader, ModalHeaderActions, ModalHeaderIconClose, ModalHeaderTitle } from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import Core from "components/Form/Core"; 
import { parseDatestringUSBR } from "utils/parsers";
import { Create, Update } from "services/process-schedules";
import { exposeStrapiError } from "utils";

export default function ModalProcessSchedule(){

    const { modal, setModal, user } = useContext(CoreContext)

    const [loading, setLoading] = useState(null)
    
    const close = () => {
        setModal(null)
    }
    
    const confirm = async () => {
        const success = await save()
        if(success){
            if(typeof modal?.success === 'function'){
                modal?.success()
            }
            setModal({
                type:"success",
                title:  modal?.id ? "Salvo com sucesso" : "Nova pauta criada com sucesso!"
            })
        }
    }
    
    const refForm = useRef()
    const [register, setRegister] = useState(null)
    
    const formData = [
        { placeholder:"Título da votação", ref:"title", full:true },
    ]

    const save = async () => {
        setLoading(true)
        const form = refForm?.current?.getForm() || {}

        const payload = {
            title: form?.title,
            status: "scheduled",
            process: modal?.process_id
        }

        if(modal?.id){
            delete payload.status
        }

        const result = modal?.id ? await Update({ data:payload }, modal?.id) : await Create({ data:payload })
        setLoading(false)

        if(result && !exposeStrapiError(result)){
            return true
        }
        return false
    }

    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>{ modal?.id ? "Editar" : "Nova"} pauta</ModalHeaderTitle>
                        {/* <ModalHeaderIconClose onClick={close} /> */}
                    </ModalHeader>
                    <ModalBody>
                        <Core formItems={formData} register={register} ref={refForm}  />
                    </ModalBody>
                    <ModalHeaderActions>
                        <Button primary outline onClick={close}>Cancelar</Button>
                        <Button primary onClick={confirm} loading={loading}>Salvar</Button>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}