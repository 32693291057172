import React, { useContext, useRef, useState } from "react";

import { ModalCard, ModalHeader, ModalHeaderActions, ModalHeaderIconClose, ModalHeaderTitle } from "./styled";

import ModalWrapper from "../Wrapper";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function ModalSuccess(){

    const { modal, setModal } = useContext(CoreContext)
    
    const close = () => {
        setModal(null)
    }
    
    
    return (
        <>
            <ModalWrapper>
                <ModalCard>
                    <ModalHeader>
                        <ModalHeaderTitle>{ modal?.title }</ModalHeaderTitle>
                    </ModalHeader>
                    <ModalHeaderActions>
                        <Button primary onClick={close}>Fechar</Button>
                    </ModalHeaderActions>
                </ModalCard>
            </ModalWrapper>
        </>
    )
}