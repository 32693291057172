import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CheckContainer, EmptyMessage, Load, LoadCenter, ResponsibleTable, ResponsibleTableContent, WhiteBg } from 'ui/styled';
import Check from '../Check';
import { theme } from 'ui/theme-color';

const StyledTableCell = styled(TableCell)(({ theme, color = "primary" }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette?.[color]?.main,
      color: theme.palette.colors.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableContainer = styled(TableContainer)(({ theme, color = "primary" }) => ({
    overflowX:"auto"
}));


export default function BasicTable({ columns, rows, loading, selectable, color }) {

  const [selected, setSelected] = React.useState([])

  const isSelected = item => {
    return selected?.map(m => m?.id)?.includes(item?.id)
  }

  const toggleSelected = item => {
    setSelected( isSelected(item) ? [ ...selected ]?.filter(f => f?.id !== item?.id ) : [ ...selected, item ] )
  }

  const toggleAll = () => {
      setSelected( selected?.length === rows?.length ? [] : rows )
  }

  React.useEffect(() => {
    if(typeof selectable === 'function'){
      selectable(selected)
    }
  }, [selected, selectable])

  return (
    <ResponsibleTable>
      <ResponsibleTableContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 150 }} style={{ borderRadius: 4 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {
                    !!selectable ? 
                      <StyledTableCell align={"center" } >
                        <WhiteBg>
                          <CheckContainer>
                            <Check checked={ selected?.length === rows?.length } onChange={() => toggleAll()} />
                          </CheckContainer>
                        </WhiteBg>
                      </StyledTableCell>
                    : null
                }
                {
                    columns?.map((item, key) => 
                        <StyledTableCell key={key} color={color} align={key === 0 ? "left" : "right" } >{ item.title }</StyledTableCell>
                    )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    {
                        !!selectable ? 
                          <TableCell align={"center" } >
                            <WhiteBg>
                              <CheckContainer>
                                <Check checked={isSelected(row)} onChange={() => toggleSelected(row)} />
                              </CheckContainer>
                            </WhiteBg>
                          </TableCell>
                        : null
                    }
                    {
                        columns?.map((item, key) => {
                          const canClickAction = row?.action && !item?.['renderCell'];
                          return (
                            <TableCell key={key} align={ key === 0 ? "left" :  "right" } 
                                style={{
                                  background:  row?.background ? theme.palette?.[color ? color : "colors"]?.[row?.background] : (row?.totalizer ? theme.palette.colors.backgroundcard : item?.background ? theme.palette?.[color ? color : "colors"]?.[item?.background] :  `transparent` ),
                                  color:  row?.color ? theme.palette?.[color ? color : "colors"]?.[row?.color] : theme.palette.colors?.text,
                                  cursor: canClickAction ? "pointer" : "auto"
                                }}
                                onClick={ canClickAction ? row?.action : null } >
                                { item?.['renderCell'] ? item.renderCell({row}) : row?.[item.ref] }
                            </TableCell>
                          )
                        })
                    }
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {
            !loading ? <>
              { rows?.length ? null : <EmptyMessage>Nenhum registro encontrado</EmptyMessage> }
            </> : 
              <LoadCenter>
                <Load />
              </LoadCenter> 
          }
        </TableContainer>
      </ResponsibleTableContent>
    </ResponsibleTable>
  );
}