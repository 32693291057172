import React, { useContext, useEffect, useState } from "react";  
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {  
    DashboardTitle,
    ContentCardTable,
} from "./styled";

import BasicTable from "components/Form/Table";
import CardMore from "components/Dashboard/CardMore";
import { RowResult, RowResultItem, RowResultItemTable, RowResultScroll, RowResultScrollContainer, RowResultScrollContent } from "screens/Dashboard/AssemblyDetails/styled";
import { ReadProcessSchedule } from "services/votings";
import { normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { optionsClassification } from "utils/options";
import { Read } from "services/process-users";
import { Read as ReadPreUsers } from "services/pre-users";
import { parseCurrency, parsePercentage } from "utils/parsers";
import { CoreContext } from "context/CoreContext";

export default function RowVoteResult({ title, item }){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { openToPrint } = useContext(CoreContext)  

    // const openToPrint = true

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ loading, setLoading ] = useState(false)
   
    const columnsClass = [
        { title:'Credores por classe ', ref:'creditor_per_class' },
    ] 
    
    const columns = [
        { title:'Nr. de credores', ref:'total_creditor' },
        { title:'Crédito total por Classe (2ª lista)', ref:'total_credit_per_class' },

        { title:'Habilitação', ref:'qualification', background:"backgroundgrey" },
        { title:' ', ref:'qualification_value', background:"backgroundgrey" },
        { title:'Quórum', ref:'quorum' },
        { title:' ', ref:'quorum_value' },
        { title:'Abstenções', ref:'abstentions', background:"backgroundgrey" },
        { title:' ', ref:'abstentions_value', background:"backgroundgrey" },
        { title:'Base para votação', ref:'vote_basis' },
        { title:' ', ref:'vote_basis_value' },
    ] 
    
    const columnsRefuse = [
        { title:'Desaprovação', ref:'refuses' },
        { title:' ', ref:'refuses_value' }
    ] 
    
    const columnsApprove = [
        { title:'Aprovação', ref:'approves' },
        { title:' ', ref:'approves_value' }
    ] 

    const [rows, setRows] = useState([])
    // [
        
    //     { creditor_per_class:"Classe 1", total_creditor:"12", total_credit_per_class:"12.345.678,90", qualification:"Credor", qualification_value:"Valor",  quorum:"Credor", quorum_value:"Valor", abstentions:"Credor", abstentions_value:"Valor", vote_basis:"Credor", vote_basis_value:"Valor", refuses:"Credor", refuses_value:"Valor", approves:"Credor", approves_value:"Valor" },
    //     { creditor_per_class:" . \n ", total_creditor:"100%", total_credit_per_class:"100%", qualification:"0%", qualification_value:"0%", quorum:"0%", quorum_value:"0%",  abstentions:"-", abstentions_value:"-", vote_basis:"0%", vote_basis_value:"0%", refuses:"0%", refuses_value:"0%", approves:"0%", approves_value:"0%" },
        
    //     { creditor_per_class:"Classe 1", total_creditor:"12", total_credit_per_class:"12.345.678,90", qualification:"Credor", qualification_value:"Valor",  quorum:"Credor", quorum_value:"Valor", abstentions:"Credor", abstentions_value:"Valor", vote_basis:"Credor", vote_basis_value:"Valor", refuses:"Credor", refuses_value:"Valor", approves:"Credor", approves_value:"Valor", background:"lightgreen" },
    //     { creditor_per_class:" . \n ", total_creditor:"100%", total_credit_per_class:"100%", qualification:"0%", qualification_value:"0%", quorum:"0%", quorum_value:"0%",  abstentions:"-", abstentions_value:"-", vote_basis:"0%", vote_basis_value:"0%", refuses:"0%", refuses_value:"0%", approves:"0%", approves_value:"0%", background:"lightgreen" },
        
    //     { creditor_per_class:"Classe 1", total_creditor:"12", total_credit_per_class:"12.345.678,90", qualification:"Credor", qualification_value:"Valor",  quorum:"Credor", quorum_value:"Valor", abstentions:"Credor", abstentions_value:"Valor", vote_basis:"Credor", vote_basis_value:"Valor", refuses:"Credor", refuses_value:"Valor", approves:"Credor", approves_value:"Valor" },
    //     { creditor_per_class:" . \n ", total_creditor:"100%", total_credit_per_class:"100%", qualification:"0%", qualification_value:"0%", quorum:"0%", quorum_value:"0%",  abstentions:"-", abstentions_value:"-", vote_basis:"0%", vote_basis_value:"0%", refuses:"0%", refuses_value:"0%", approves:"0%", approves_value:"0%" },
        
    //     { totalizer:true, creditor_per_class:"Total", total_creditor:"12", total_credit_per_class:"12.345.678,90", qualification:"Credor", qualification_value:"Valor",  quorum:"Credor", quorum_value:"Valor", abstentions:"Credor", abstentions_value:"Valor", vote_basis:"Credor", vote_basis_value:"Valor", refuses:"Credor", refuses_value:"Valor", approves:"Credor", approves_value:"Valor", background:"primary", color:"white" },
    //     { creditor_per_class:". ", total_creditor:"36", total_credit_per_class:"100%", qualification:"0%", qualification_value:"0%", quorum:"0%", quorum_value:"0%",  abstentions:"-", abstentions_value:"-", vote_basis:"0%", vote_basis_value:"0%", refuses:"0%", refuses_value:"0%", approves:"0%", approves_value:"0%", background:"lightprimary" },

    // ]
    
    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const init = async () => {
        if(item?.id && item?.status === 'finished'){
            setLoading(true)
            const users_process = await Read(item?.process?.id)
            const preusers_process = await ReadPreUsers(item?.process?.id)
            const result = await ReadProcessSchedule(item?.id)



            setLoading(false)
            if(result?.data?.length && users_process?.data?.length){

                const normalResult = normalizeStrapiList(result)
                
                const nusersproc = normalizeStrapiList(users_process)
                const prenusersproc = normalizeStrapiList(preusers_process)
                
                // console.log("normalResult", normalResult, nusersproc)
                console.log("prenusersproc", prenusersproc)

                const normalusers_process = nusersproc?.map((it) => {
                    const normalUser = !it?.user_creditor ? null : normalizeStrapiRegister(it?.user_creditor)

                    const voted = normalResult?.map(m => m?.user?.data?.id)?.includes(normalUser?.id)
                    const vote = normalResult?.find(m => m?.user?.data?.id === normalUser?.id)?.vote

                    return {
                        ...it,

                        classification: normalUser?.['class'],
                        raw_value: it?.value, 
                        enabled: it?.enabled, 
                        voted,
                        vote,
                        user: { ...normalUser, value:it?.value, voted, vote, enabled: it?.enabled }
                    }
                }).filter(f => f?.user?.type !== 'attorney' && f?.user?.type !== 'admin' && f?.user?.type !== 'sindicate')

                const nextRows = normalResult?.map( m => {
                    const normalUser = normalizeStrapiRegister(m?.user)
                    const process_user = normalusers_process?.find(f => f?.user?.id === normalUser?.id )?.user 


                    return { 
                        // id: m?.id,
                        // name: normalUser?.name, 
                        // classification: optionsClassification?.find(f => f.ref === normalUser?.['class'])?.title, 
                        // value: parseCurrency(normalUser?.value || 0), 
                        // attorney: " ", 
                        // vote: optionsVoted?.find(f => f.ref === m?.vote)?.title,
                        
                        classification: normalUser?.['class'],
                        raw_value: process_user?.value, 
                        enabled: process_user?.enabled, 
                        voted: process_user?.voted,
                        vote: process_user?.vote,
                        user: normalUser
                    }
                    
                }).filter(f => f?.user?.type !== 'attorney' && f?.user?.type !== 'admin' && f?.user?.type !== 'sindicate')

                console.log("nextRows", nextRows, normalusers_process)

                const fulllength = prenusersproc?.length
                const fullvalue = prenusersproc?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0
                
                const fullenlength = nextRows?.filter(f => f.enabled)?.length
                const fullenvalue = nextRows?.filter(f => f.enabled)?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                
                const fullqolength = nextRows?.filter(f => f.voted)?.length
                const fullqovalue = nextRows?.filter(f => f.voted)?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                
                const fullabslength = nextRows?.filter(f => f.vote === "abstention")?.length
                const fullabsvalue = nextRows?.filter(f => f.vote === "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                
                const fullbaselength = nextRows?.filter(f => f.vote !== "abstention")?.length
                const fullbasevalue = nextRows?.filter(f => f.vote !== "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                const fullrefusedlength = nextRows?.filter(f => f.vote === "failed")?.length
                const fullrefusedvalue = nextRows?.filter(f => f.vote === "failed")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                const fullapprovedlength = nextRows?.filter(f => f.vote === "approved")?.length
                const fullapprovedvalue = nextRows?.filter(f => f.vote === "approved")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0


                const resultClass = optionsClassification?.map( m => {

                    const uclass = prenusersproc?.filter(f => f.classification === m?.ref)
                    const tuclass = uclass?.length
                    const tuvalue = uclass?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0

                    const alluclass = normalusers_process?.filter(f => f.classification === m?.ref)


                    // const alltuclass = alluclass?.length
                    // const alltuvalue = alluclass?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0

                    // console.log("alluclass", alltuclass, alltuvalue)
                    
                    const entuclass = alluclass?.length
                    const entuvalue = alluclass?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                    const qotuclass = alluclass?.filter(f => f.voted)?.length
                    const qotuvalue = alluclass?.filter(f => f.voted)?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                    const abstuclass = alluclass?.filter(f => f.vote === "abstention")?.length
                    const abstuvalue = alluclass?.filter(f => f.vote === "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                    const basetuclass = alluclass?.filter(f => f.vote !== "abstention")?.length
                    const basetuvalue = alluclass?.filter(f => f.vote !== "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                    const refusedtuclass = alluclass?.filter(f => f.vote === "failed")?.length
                    const refusedtuvalue = alluclass?.filter(f => f.vote === "failed")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                    const approvedtuclass = alluclass?.filter(f => f.vote === "approved")?.length
                    const approvedtuvalue = alluclass?.filter(f => f.vote === "approved")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                    
                    //     

                    const base_creditor = parseFloat((100 * tuclass ) / tuclass) || 0
                    const base_value = parseFloat((100 * tuvalue) / tuvalue) || 0

                    const en_creditor = parseFloat((100 * entuclass) / tuclass) || 0
                    const en_value = parseFloat((100 * entuvalue) / tuvalue) || 0  

                    const qo_creditor = parseFloat((100 * qotuclass) / tuclass) || 0
                    const qo_value = parseFloat((100 * qotuvalue) / tuvalue) || 0

                    const abs_creditor = parseFloat((100 * abstuclass) / tuclass) || 0
                    const abs_value = parseFloat((100 * abstuvalue) / tuvalue) || 0

                    //

                    const base_vote_creditor = parseFloat((100 * basetuclass) / basetuclass) || 0
                    const base_vote_value = parseFloat((100 * basetuvalue) / basetuvalue) || 0

                    
                    const refused_creditor = parseFloat((100 * refusedtuclass) / basetuclass) || 0
                    const refused_value = parseFloat((100 * refusedtuvalue) / basetuvalue) || 0

                    const approved_creditor = parseFloat((100 * approvedtuclass) / basetuclass) || 0
                    const approved_value = parseFloat((100 * approvedtuvalue) / basetuvalue) || 0

                    return [
                        { creditor_per_class: m?.title, total_creditor:tuclass, total_credit_per_class: parseCurrency(tuvalue), qualification: entuclass, qualification_value: parseCurrency(entuvalue),  quorum: qotuclass, quorum_value: parseCurrency(qotuvalue), abstentions: abstuclass, abstentions_value: parseCurrency(abstuvalue), vote_basis:basetuclass, vote_basis_value:  parseCurrency(basetuvalue), refuses:refusedtuclass, refuses_value: parseCurrency(refusedtuvalue), approves:approvedtuclass, approves_value: parseCurrency(approvedtuvalue) },
                        { creditor_per_class:" . \n ", total_creditor:`${parsePercentage(base_creditor)}`, total_credit_per_class:`${parsePercentage(base_value)}`, qualification:`${parsePercentage(en_creditor)}`, qualification_value:`${parsePercentage(en_value)}`, quorum:`${ parsePercentage(qo_creditor )}`, quorum_value:`${ parsePercentage(qo_value )}`,  abstentions:`${parsePercentage(abs_creditor)}`, abstentions_value:`${parsePercentage(abs_value)}`, vote_basis:`${parsePercentage(base_vote_creditor)}`, vote_basis_value:`${parsePercentage(base_vote_value)}`, refuses:`${parsePercentage(refused_creditor)}`, refuses_value:`${parsePercentage(refused_value)}`, approves:`${parsePercentage(approved_creditor)}`, approves_value:`${parsePercentage(approved_value)}` },
                    ]

                })

                const ttclass = prenusersproc?.length
                const ttvalue = prenusersproc?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0


                const ttfulllength = prenusersproc?.length
                const ttfullvalue = prenusersproc?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0
                
                const ttfullenlength = normalusers_process?.length
                const ttfullenvalue = normalusers_process?.reduce((p, c) => p + (c?.value || 0), 0 ) || 0
                
                const ttfullqolength = normalusers_process?.filter(f => f.voted)?.length
                const ttfullqovalue = normalusers_process?.filter(f => f.voted)?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                
                const ttfullabslength = normalusers_process?.filter(f => f.vote === "abstention")?.length
                const ttfullabsvalue = normalusers_process?.filter(f => f.vote === "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0
                
                const ttfullbaselength = normalusers_process?.filter(f => f.vote !== "abstention")?.length
                const ttfullbasevalue = normalusers_process?.filter(f => f.vote !== "abstention")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                const ttfullrefusedlength = normalusers_process?.filter(f => f.vote === "failed")?.length
                const ttfullrefusedvalue = normalusers_process?.filter(f => f.vote === "failed")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0

                const ttfullapprovedlength = normalusers_process?.filter(f => f.vote === "approved")?.length
                const ttfullapprovedvalue = normalusers_process?.filter(f => f.vote === "approved")?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0



                const qo_creditor = parseFloat((100 * ttfullqolength) / ttclass) || 0
                const qo_value = parseFloat((100 * ttfullqovalue) / ttvalue) || 0

                const abs_creditor = parseFloat((100 * ttfullabslength) / ttclass) || 0
                const abs_value = parseFloat((100 * ttfullabsvalue) / ttvalue) || 0

                const en_creditor = parseFloat((100 * ttfullenlength) / ttclass) || 0
                const en_value = parseFloat((100 * ttfullenvalue) / ttvalue) || 0

                const base_creditor = parseFloat((100 * ttfulllength) / ttclass) || 0
                const base_value = parseFloat((100 * ttfullvalue) / ttvalue) || 0

                const base_vote_creditor = parseFloat((100 * ttfullbaselength) / ttfullbaselength) || 0
                const base_vote_value = parseFloat((100 * ttfullbasevalue) / ttfullbasevalue) || 0

                //

                const refused_creditor = parseFloat((100 * ttfullrefusedlength) / ttfullbaselength) || 0
                const refused_value = parseFloat((100 * ttfullrefusedvalue) / ttfullbasevalue) || 0

                const approved_creditor = parseFloat((100 * ttfullapprovedlength) / ttfullbaselength) || 0
                const approved_value = parseFloat((100 * ttfullapprovedvalue) / ttfullbasevalue) || 0

                setRows([
                    { creditor_per_class: " . \n ", total_creditor:"Credor", total_credit_per_class:"Valor", qualification: "Credor", qualification_value:"Valor",  quorum: "Credor", quorum_value:"Valor", abstentions: "Credor", abstentions_value:"Valor", vote_basis:"Credor", vote_basis_value:"Valor", refuses:"Credor", refuses_value:"Valor", approves:"Credor", approves_value:"Valor" },
                    ...resultClass?.reduce((p, c) =>  [...p, ...c],[]),
                         
                    { totalizer:true, creditor_per_class:"Total", total_creditor: ttclass, total_credit_per_class:parseCurrency(ttvalue), qualification: ttfullenlength, qualification_value:parseCurrency(ttfullenvalue),  quorum: ttfullqolength, quorum_value: parseCurrency(ttfullqovalue), abstentions:ttfullabslength, abstentions_value:parseCurrency(ttfullabsvalue), vote_basis:ttfullbaselength, vote_basis_value:parseCurrency(ttfullbasevalue), refuses: ttfullrefusedlength, refuses_value:parseCurrency(ttfullrefusedvalue), approves:ttfullapprovedlength, approves_value: parseCurrency(ttfullapprovedvalue), background:"primary", color:"white" },
                    { creditor_per_class:" . \n ", total_creditor:`${parsePercentage(base_creditor)}`, total_credit_per_class:`${parsePercentage(base_value)}`, qualification:`${parsePercentage(en_creditor)}`, qualification_value:`${parsePercentage(en_value)}`, quorum:`${ parsePercentage(qo_creditor) }`, quorum_value:`${ parsePercentage(qo_value) }`,  abstentions:`${ parsePercentage(abs_creditor) }`, abstentions_value:`${ parsePercentage(abs_value) }`, vote_basis:`${ parsePercentage(base_vote_creditor) }`, vote_basis_value:`${parsePercentage(base_vote_value)}`, refuses:`${parsePercentage(refused_creditor)}`, refuses_value:`${parsePercentage(refused_value)}`, approves:`${parsePercentage(approved_creditor)}`, approves_value:`${parsePercentage(approved_value)}`, background:"lightprimary" },
                ])

                // setRows([
                //     ...nextRows,
                //     { name:"Total", classification:"Classificação de Crédito", value:`Valor ${ parseCurrency( nextRows?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0 ) }`, totalizer:true }
                // ])

            }
        }
    }

    useEffect(() => { init() ;}, [ item ])

    return ( 
        <>
            <CardMore title={title} openToPrint={openToPrint}>
                {
                    !rows?.length ? null : 
                    <ContentCardTable>
                        <DashboardTitle>Resultado por Classe</DashboardTitle>
                        <RowResult>
                            <RowResultItem>
                                <BasicTable loading={loading}  columns={columnsClass} rows={rows?.filter(filterExpression)} />
                            </RowResultItem>
                            <RowResultScroll closed={openToPrint}>
                                    <RowResultScrollContent>

                                        <RowResultScrollContainer>
                                            <BasicTable loading={loading}  columns={columns} rows={rows?.filter(filterExpression)} />
                                        </RowResultScrollContainer>

                                        <RowResultItemTable>
                                            <BasicTable loading={loading} columns={columnsRefuse} rows={rows?.filter(filterExpression)} color={"red"} />
                                        </RowResultItemTable>
                                        <RowResultItemTable>
                                            <BasicTable loading={loading} columns={columnsApprove} rows={rows?.filter(filterExpression)} color={"blue"} />
                                        </RowResultItemTable>
                                        
                                    </RowResultScrollContent>
                            </RowResultScroll>
                        </RowResult>
                    </ContentCardTable>
                }
            </CardMore> 
        </>
    );
}