import styled from 'styled-components'  
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: bold;
    font-size: 20px; 

    margin: 10px 0 10px;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.text };

    margin-bottom: 30px;
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
    ${
        p => p.small ? `
            margin-top: 10px;
        
        ` : ``
    }
`;

export const RegisterForgot = styled.div.attrs({ 
})`           
    margin-top: 40px;
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };
    display: flex;
`;

export const ForgotLink = styled.div.attrs({ 
})`           
    margin-left: 6px;
    color: ${ props => props.theme.palette.primary.main };
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`;

export const RegisterCall = styled.div.attrs({ 
})`           
    margin: 30px 0 0px;
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 15px;
    font-weight: bold;
`;