import React, { useContext, useEffect, useState } from "react";  
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {  
    
    HeaderActionItem,
    ButtonButtonIconPlay,

    CardVote,
    CardVoteTitle,
    CardVoteActions,
    CardVoteActionsIcon,
    CardVoteAction,
    CardVoteActionsContainer,
    CardVoteTable,

    VoteItem

} from "./styled";

import BasicTable from "components/Form/Table";

import { 
    ItemIcon
} from "ui/styled";

import Button from "components/Form/Button";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { Update } from "services/process-schedules";
import { Read, ReadProcessSchedule } from "services/votings";
import { Read as ReadProcUsers } from "services/process-users";
import { optionsClassification, optionsVoted } from "utils/options";
import { parseCurrency } from "utils/parsers";
import { CoreContext } from "context/CoreContext";

export default function RowVote({ title, completed, item, reload, edit, remove }){  

    const { openToPrint } = useContext(CoreContext)

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ loading, setLoading ] = useState(false)
    
    const [ open, setOpen ] = useState(false) 
    const [ starting, setStarting ] = useState(false) 

    const columns = [
        { title:'Relação de credores', ref:'name' },
        { title:'Classificação de Crédito', ref:'classification' },
        { title:'Valor R$', ref:'value' },
        { title:'Procuradores', ref:'attorney' },
        { 
            title:'Voto', 
            renderCell: ({ row }) => <>
                <VoteItem status={ row?.vote }>
                    { row?.vote }
                </VoteItem>
            </>
        },
    ] 

    const [rows, setRows] = useState([])
    // [
    //     { name:"Nome do credor", classification:"Classe I", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Abstenção" },
    //     { name:"Nome do credor", classification:"Classe I", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     { name:"Nome do credor", classification:"Classe I", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     { name:"Nome do credor", classification:"Classe II", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Reprovado" },
    //     { name:"Nome do credor", classification:"Classe II", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     { name:"Nome do credor", classification:"Classe II", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     { name:"Nome do credor", classification:"Classe III", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     { name:"Nome do credor", classification:"Classe III", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Abstenção" },
    //     { name:"Nome do credor", classification:"Classe IV", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Reprovado" },
    //     { name:"Nome do credor", classification:"Classe IV", value:"12.345.678,90", attorney:"João da Silva Almeida Pinheiro", vote:"Aprovado" },
    //     // totalizer
    //     { name:"Total", classification:"Classificação de Crédito", value:"Valor R$ 12.345.678,90", totalizer:true },
    // ]

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const scheduleAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await Update({ data:{ status: "scheduled" } }, item?.id)
            if(result && !exposeStrapiError(result)){
                reload();
            }
            setStarting(false)
        }
    }

    const startAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await Update({ data:{ status: "running" } }, item?.id)
            if(result && !exposeStrapiError(result)){
                reload();
            }
            setStarting(false)
        }
    }

    const completeAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await Update({ data:{ status: "finished" } }, item?.id)
            if(result && !exposeStrapiError(result)){
                reload();
            }
            setStarting(false)
        }
    }

    const init = async () => {
        if(item?.id && item?.status === 'finished'){
            setLoading(true)
            const result = await ReadProcessSchedule(item?.id)
            const allUsers = await ReadProcUsers(item?.process?.id)

            setLoading(false)
            if(result?.data?.length){
                const normalResult = normalizeStrapiList(result)
                const normalAllUsers = normalizeStrapiList(allUsers)

                const nextRows = normalResult?.map( m => {
                    const normalUser = normalizeStrapiRegister(m?.user)
                    const procUser = normalAllUsers?.find(f => f?.user_creditor?.data?.id === normalUser?.id)
                    return { 
                        id: m?.id,
                        name: normalUser?.name, 
                        classification: optionsClassification?.find(f => f.ref === normalUser?.['class'])?.title, 
                        value: parseCurrency(procUser?.value || 0), 
                        raw_value: procUser?.value, 
                        attorney: procUser?.user_creditor?.data?.attributes?.user_prosecutor?.data?.attributes?.name || " ", 
                        vote: optionsVoted?.find(f => f.ref === m?.vote)?.title,
                    }

                })

                setRows([
                    ...nextRows,
                    { name:"Total", classification:"Classificação de Crédito", value:`Valor ${ parseCurrency( nextRows?.reduce((p, c) => p + (c?.raw_value || 0), 0 ) || 0 ) }`, totalizer:true }
                ])
            }
        }
    }

    useEffect(() => { init() ;}, [item])

    return ( 
        <> 
            <CardVote>
                <CardVoteTitle>{ title }</CardVoteTitle>

                {
                    completed ? 
                        <CardVoteActions onClick={() => setOpen(!open)}>
                            Votação finalizada
                            <CardVoteActionsIcon open={open || openToPrint} />
                        </CardVoteActions>
                    :
                        <CardVoteActionsContainer>
                            <CardVoteAction>
                                <ItemIcon icon={"trash"} onClick={remove} />
                            </CardVoteAction>
                            <CardVoteAction onClick={edit}>
                                <ItemIcon icon={"edit"} />
                            </CardVoteAction>
                            <HeaderActionItem>
                                {
                                    item?.status !== "scheduled" ? null : 
                                        <Button nospace outline secondary loading={starting} onClick={startAssemble}>
                                            <ButtonButtonIconPlay />
                                            Iniciar votação
                                        </Button>
                                }
                                {
                                    item?.status !== "running" ? null : 
                                        <Button nospace outline secondary loading={starting} onClick={completeAssemble}>
                                            Finalizar votação
                                        </Button>
                                }
                            </HeaderActionItem>
                        </CardVoteActionsContainer>
                }

            </CardVote>
            {
                open || openToPrint ? 
                    <CardVoteTable>
                        <BasicTable loading={loading}  columns={columns} rows={rows?.filter(filterExpression)} />
                    </CardVoteTable>
                : null
            }
        </>
    );
}