import React, { createRef, useEffect, useRef, useState } from "react";  
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import {  
    DashboardTitle,
    ContentCardTable,


    HeaderRow,
    HeaderContent,
    HeaderActions,
    
    
    RowCenter,
    RowCenterItem,
    ButtonIconAdd,


    ContentWrapperCards,
    UploadCard,
    UploadCardDecoration,
    UploadCardDecorationLabel,
    UploadCardDecorationIcon,
    UploadCardDecorationAction,
    UploadCardDecorationActionIcon,
    UploadCardTitle
} from "./styled";

import { Row, Col } from "reactstrap";
import { optionsClassification, optionsPerson } from "utils/options";

import ContainerAuthenticated from "containers/Authenticated";

import Button from "components/Form/Button";
import Toggle from "components/Form/Toggle";

import Core from "components/Form/Core";
import UploadFile from "components/Form/UploadFile";
import { Create, Read, ReadOne, ReadUserByCPF, ReadUserByEmail, Update } from "services/users";
import { Create as CreateProcessUser, ReadOne as ReadOneProcessUser, Update as UpdateProcessUser } from "services/process-users";
import { exposeStrapiError, normalizeStrapiRegister } from "utils";
import { toast } from "react-toastify";
import { Crypt } from "services/storage";

export default function DashboardCreateCreditor(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { processId, processUserId, id } = useParams()

    const [ loading, setLoading ] = useState(false) 
    const [enabled, setEnabled ] = useState(false) 
    const [register, setRegister] = useState(null)
    
    const [users, setUsers] = useState([])
    const [files, setFiles] = useState([])
    
    const refForm = useRef()

    const formData = [
        { placeholder:"Nome do credor", ref:"name" },
        { placeholder:"Representante / Procurador", ref:"agent" },
        // { placeholder:"Representante / Procurador", ref:"user_creditor", options:users },
        // { placeholder:"Representante", ref:"user_creditor" },
        { placeholder:"Tipo", ref:"type", options: optionsPerson },

        { placeholder:"Classificação de crédito", ref:"classification", options: optionsClassification },
        // { placeholder:"Procurador", ref:"user_prosecutor", options:users },
        
        { placeholder:"CPF", ref:"cpf", mask:"999.999.999-99" },
        { placeholder:"Valor (R$)", ref:"value", mask:"money" },
        
        { placeholder:"Email", ref:"email", half:true  },
        { placeholder:"Telefone", ref:"phone", mask:"(99) 99999-9999", half:true  },
    ]

    const removeFile = key => {
        setFiles(files?.filter((f,k) => k !== key))
    } 

    const changeFile = (file) => {
        setLoading(true)
        const nfiles = [...files, file]
        setFiles([ ...nfiles ])
        setTimeout(() => setLoading(false), 150)
    }

    const init = async () => {
        setLoading(true)
        const result = await Read()

        if(id){
            const pregister = await ReadOne(id)
            const procuser = await ReadOneProcessUser(processUserId)
            const pprocuser = normalizeStrapiRegister(procuser)

            setRegister({
                ...pregister,
                // user_creditor: pregister?.user_creditor?.id, 
                type: optionsPerson?.find(f => f.ref === pregister?.type_person)?.id, 
                classification: optionsClassification?.find(f => f.ref === pregister?.['class'])?.id,
                agent: pprocuser?.procurator, 
                value: pprocuser?.value, 
            })

            setEnabled(!!pprocuser?.enabled)
            setFiles(pregister?.files)
        }


        setUsers((result || [])?.map(m => ({ ...m, title:m?.name })))
        setLoading(false)
    }

    const relationBeforeSafe = async () => {
        if(!id){
            const form = refForm?.current?.getForm()
    
            const resultCpf = await ReadUserByCPF(form?.cpf)
            const resultEmail = await ReadUserByEmail(form.email?.replace(/ /g,''))
    
            if(resultCpf?.length || resultEmail?.length){ 
    
                const result = resultCpf?.[0] || resultEmail?.[0]
    
                const prorcessCreated = processUserId ? 
                    await UpdateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(form?.value || 0), procurator: form?.agent, process: processId } }, processUserId) 
                        : await CreateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(form?.value || 0), procurator: form?.agent, process: processId } }) 
    
                console.log("prorcessCreated", prorcessCreated)
    
                toast.success("Salvo com sucesso!")
                history.goBack();
                
                setLoading(false)
                return true
            }
        }
        return false
    }

    const save = async () => {
        if(!loading){
            setLoading(true)
            
            if(await relationBeforeSafe()){ return ;}
    
            const form = refForm?.current?.getForm()
            const payload = {
    
                ...form,
                
                username: form.email?.replace(/ /g,''),
                email: form.email?.replace(/ /g,''),
                confirmed:true,
                blocked: false,
                role: 1,
                
                status: enabled,
                'class': optionsClassification?.find(f => `${f.id}` === `${form?.classification}`)?.ref,
                type_person: optionsPerson?.find(f => `${f.id}` === `${form?.type}`)?.ref,
                files: files?.map(m => m?.id),
    
                password: Crypt(`${ new Date().getTime() }`),
                type:'creditor'
            }
    
            const result = id ? await Update({ ...payload }, id) : await Create({ ...payload })
    
            if(result && !exposeStrapiError(result)){
    
                const prorcessCreated = processUserId ? 
                    await UpdateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(form?.value || 0), procurator: form?.agent, process: processId, enabled } }, processUserId) 
                        : await CreateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(form?.value || 0), procurator: form?.agent, process: processId, enabled } }) 
                
                console.log("prorcessCreated", prorcessCreated)
    
                toast.success("Salvo com sucesso!")
                history.goBack();
            }
    
    
            setLoading(false)
        }
    }

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated page={0}> 
                <Row>
                    <Col> 

                        <HeaderRow>
                            {/* <HeaderBack /> */}
                            <HeaderContent>
                                <DashboardTitle> Cadastro do credor </DashboardTitle> 
                            </HeaderContent>
                            
                            <HeaderActions> 
                                <Button nospace outline secondary onClick={history.goBack}>
                                    Cancelar
                                </Button> 
                                <Button nospace primary onClick={save}>
                                    Salvar
                                </Button>  
                            </HeaderActions>
                        </HeaderRow>

                        <ContentCardTable>
                            <Core formItems={formData} register={register} ref={refForm} />
                        </ContentCardTable>
                        
                        <ContentCardTable>
                            <DashboardTitle> Upload dos documentos do processo </DashboardTitle> 

                            <ContentWrapperCards>
                                {
                                    files?.map((item, key) => 
                                        <UploadCard key={key}>
                                            <UploadCardDecoration>
                                                <UploadCardDecorationLabel>{ item?.ext }</UploadCardDecorationLabel>
                                                <UploadCardDecorationIcon />
                                                <UploadCardDecorationAction onClick={() => removeFile(key)}>
                                                    <UploadCardDecorationActionIcon />
                                                </UploadCardDecorationAction>
                                            </UploadCardDecoration>
                                            <UploadCardTitle>{ item?.name }</UploadCardTitle>
                                        </UploadCard>
                                    )
                                }
                            </ContentWrapperCards>

                            <RowCenter>
                                <RowCenterItem>
                                    {
                                        loading ? null :
                                        <UploadFile onChange={file => changeFile(file)}>
                                            <Button outline secondary loading={loading}>
                                                <ButtonIconAdd />
                                                Novo
                                            </Button>
                                        </UploadFile>
                                    }
                                </RowCenterItem>
                            </RowCenter>

                        </ContentCardTable> 
                       
                        <ContentCardTable>
                            <HeaderRow>
                                <DashboardTitle> Credor habilitado? </DashboardTitle> 
                                <HeaderActions>
                                    <Toggle checked={enabled} onChange={() => setEnabled(!enabled)}/>
                                </HeaderActions>
                            </HeaderRow>
                        </ContentCardTable>

                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}