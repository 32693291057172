export const optionsStates = [
    { id:1, title:'AC', name:'Acre' },
    { id:2, title:'AL', name:'Alagoas' },
    { id:3, title:'AP', name:'Amapa' },
    { id:4, title:'AM', name:'Amazonas' },

    { id:5, title:'BA', name:'Bahia' },
    { id:6, title:'CE', name:'Ceara' },
    { id:7, title:'DF', name:'Distrito Federal' },
    { id:8, title:'ES', name:'Espirito Santo' },
    { id:9, title:'GO', name:'Goias' },
    { id:10, title:'MA', name:'Maranhão' },

    { id:11, title:'MT', name:'Mato Grosso' },
    { id:12, title:'MS', name:'Mato Grosso do Sul' },
    { id:13, title:'MG', name:'Minas Gerias' },
    { id:14, title:'PA', name:'Pará' },
    { id:15, title:'PB', name:'Paraíba' },
    
    { id:16, title:'PR', name:'Paraná' },
    { id:17, title:'PE', name:'Pernambuco' },
    { id:18, title:'PI', name:'Piauí' },
    { id:19, title:'RJ', name:'Rio de Janeiro' },
    
    { id:20, title:'RN', name:'Rio Grande do Norte' },
    { id:21, title:'RS', name:'Rio Grande do Sul'  },
    { id:22, title:'RO', name:'Rondônia'  },
    { id:23, title:'RR', name:'Roraima' },
    
    { id:24, title:'SC', name:'Santa Catarina' },
    { id:25, title:'SP', name:'São Paulo' },
    { id:26, title:'SE', name:'Sergipe' },
    { id:27, title:'TO', name:'Tocântins' },
]

export const optionsMassActions = [
    { id:1, title:"Todos" },
    { id:2, title:"Ativo" },
    { id:3, title:"Inativo" },
]

export const optionsBoolean = [
    { id:1, title:"Sim", ref:true },
    { id:2, title:"Não", ref:false }
]

export const optionsStatus = [
    { id:1, title:"Publicado", ref:"published" },
    { id:2, title:"Não publicado", ref:"not_published" }
]

export const optionsEdictalStatus = [
    { id:1, title:"Agendada", ref:"scheduled" },
    { id:1, title:"Aberta", ref:"running" },
    { id:2, title:"Finalizada", ref:"finished" }
]

export const optionsCreditorStatus = [
    { id:1, title:"Habilitado", ref:true },
    { id:2, title:"Desabilitado", ref:false }
]

export const optionsClassification = [
    { id:1, title:"Classe I", ref:'class_1' },
    { id:2, title:"Classe II", ref:'class_2' },
    { id:3, title:"Classe III", ref:'class_3' },
    { id:4, title:"Classe IV", ref:'class_4' },
]

export const optionsEnabled = [
    { id:1, title:"Classe 1", ref:'class_1' },
    { id:2, title:"Classe 2", ref:'class_2' },
]

export const optionsPerson = [
    { id:1, title:"Pessoa fisica", ref:'individual' },
    { id:2, title:"Pessoa juridica", ref:'legal' },
]

export const optionsPresence = [
    { title:"Todos" }
]

export const optionsVotes = [
    { title:"Todos" }
]

export const optionsAssembleMassActions = [
    { id:1, title:"Habilitar selecionados", ref:'enable' },
    { id:2, title:"Presença para selecionados", ref:'presence' },
    { id:3, title:"Remover selecionados", ref:'remove' },
]

export const optionsVoted = [
    { id:1, title:"Aprovado", ref:'approved' },
    { id:2, title:"Reprovado", ref:'failed' },
    { id:3, title:"Abstenção", ref:'abstention' },
]

export const optionsVoteOptions = [
    { id:2, title:"Reprovar", ref:'failed' },
    { id:1, title:"Aprovar", ref:'approved' },
    { id:3, title:"Abster", ref:'abstention' },
]


export const ENUM_MODES = { 
    creditor:"Credor", 
    attorney:'Procurador',
    sindicate:'Sindicato',
}