import React, { useContext, useEffect, useState } from "react"; 
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import { 
    FormTitle,
    FormText,
    FormSpacer,
    RegisterForgot,
    ForgotLink,
    RegisterCall,
    ContentTerms,

    UploadTitle,
    UploadContent,
    UploadContentIcon,
    SuccessIcon,
    SearchTitle,
    SuccessContent
} from './styled' 

import Button from "components/Form/Button";
import Input, { MaskedInput } from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { DoLogin } from "services/authentication";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import Toggle from "components/Form/Toggle";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Check from "components/Form/Check";
import UploadFile from "components/Form/UploadFile";
import { Load, LoadCenter, RowBetween } from "ui/styled";
import { ReadByCode } from "services/processes";
import { Create, Read, ReadByName, ReadUserByCPF, ReadUserByEmail, Update } from "services/users";
import { ENUM_MODES, optionsClassification, optionsPerson } from "utils/options";
import { Crypt } from "services/storage";
import { API_ENDPOINT_WEB } from "services/api";

import { Read as ReadUsersByProcess, Create as CreateProcessUser, Update as UpdateProcessUser, ReadByUser } from "services/process-users";
import { ReadUserByName, ReadUserByProcuratorName, Update as UpdatePreUser } from "services/pre-users";

export default function Accreditation(){ 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const [ loading, setLoading ] = useState(false)
    const [ finding, setFinding ] = useState(false)
    const [ searching, setSearching ] = useState(false)
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const params = useParams()
    const { id, mode, type } = params

    const { setUser, setFirst } = useContext(CoreContext)

    const [step, setStep] = useState(0)

    const [currentProcess, setCurrentProcess] = useState(null)
    const [currentPreUser, setCurrentPreUser] = useState(null)
    
    const [alreadyUsers, setAlreadyUsers] = useState([])

    const [repUsers, setRepUsers] = useState([])
    const [selectedRepUsers, setSelectedRepUsers] = useState([])
    
    const [closed, setClosed] = useState(false)
    const [loging, setLoging] = useState(false)
    const [pass, setPass] = useState("")

    const [files, setFiles] = useState([])

    const init = async () => {
        if(id){
            setLoading(true)
            const result = await ReadByCode(`${API_ENDPOINT_WEB}/dashboard/accreditation/${ id }`)
            const normalResult = normalizeStrapiList(result)
            const process = normalResult?.[0]
            setCurrentProcess(process)
            // console.log("process", process)

            const currentProcessUsers = await ReadUsersByProcess( process?.id )
            const ncpu = normalizeStrapiList(currentProcessUsers)
            setAlreadyUsers(ncpu)

            setClosed(!process?.in_accreditation)
            setLoading(false)
        }
    }

    const relationBeforeSafe = async (represented_users) => { 
        // const resultCpf = await ReadUserByCPF(form?.document)
        const resultEmail = await ReadUserByEmail(form.email?.replace(/ /g,''))

        if(resultEmail?.length){ 
            
            const result = resultEmail?.[0]
            if(result?.id){
                // user already exists.. update register with
                    // name, cpf, type

                    // console.log("Updating", {
                    //     ...form,
                    //     type: mode,
                    //     rep_users: selectedRepUsers?.map(m => m?.id)
                    // })

                    const up = await Update({
                        ...form,
                        type: mode,
                        rep_users: selectedRepUsers?.map(m => m?.id)
                    }, result?.id )

            }

            
            const alreadyInProcess = await ReadByUser(result?.id)
            if(alreadyInProcess?.data?.length){
                const normalInProcess = normalizeStrapiList(alreadyInProcess)
                if(normalInProcess?.filter( f => f?.process?.data?.id === currentProcess?.id)?.length){
                    // aleardy skipping

                    const alreadyRegister = normalInProcess?.find( f => f?.process?.data?.id === currentProcess?.id)

                    console.log("alreadyRegister", alreadyRegister, represented_users )

                    /// update
                    if(alreadyRegister?.id){
                        const prereps = alreadyRegister?.represented_users?.data?.map(m => m?.id )
                        await UpdateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(currentPreUser?.value), process: currentProcess?.id, represented_users: [ ...prereps, ...represented_users ], procurator:form?.name } }, alreadyRegister?.id) 
                        const promises = prereps?.map(m => UpdateProcessUser({ data:{ procurator: form?.name } }, m) )
                        await Promise.all(promises)
                    }

                    await makeRelation( alreadyRegister?.id, currentPreUser )

                    setStep(2)
                    setLoading(false)
                    return alreadyRegister?.id
                }
            }

            const prorcessCreated = await CreateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(currentPreUser?.value), process: currentProcess?.id, represented_users, procurator:form?.name } }) 

            await makeRelation( prorcessCreated?.data?.id, currentPreUser )
            
            console.log("prorcessCreated", prorcessCreated)

            setStep(2)
            setLoading(false)
            return true
        } 


        return false
    }

    const relationCreditorBeforeSafe = async (creditor, preuser) => { 
        // const resultCpf = await ReadUserByCPF(form?.document)
        const resultEmail = await ReadUserByEmail(creditor?.email)

        if(resultEmail?.length){ 
            const result = resultEmail?.[0]

            if(result?.id){
                const up = await Update({
                    type: 'creditor'
                }, result?.id )
            }


            const alreadyInProcess = await ReadByUser(result?.id)
            if(alreadyInProcess?.data?.length){
                const normalInProcess = normalizeStrapiList(alreadyInProcess)
                if(normalInProcess?.filter( f => f?.process?.data?.id === currentProcess?.id)?.length){
                    const alreadyRegister = normalInProcess?.find( f => f?.process?.data?.id === currentProcess?.id)

                    console.log("already creditor ?", alreadyRegister)

                    /// update
                    if(alreadyRegister?.id){
                        await UpdateProcessUser({ data:{ user_creditor: result?.id, value: parseInt(preuser?.value), procurator: form?.name, process: currentProcess?.id } }, alreadyRegister?.id) 
                    }

                    await makeRelation( alreadyRegister?.id, preuser )
                    return alreadyRegister?.id
                }
            }
            const processed = await CreateProcessUser({ data:{ user_creditor: result?.id, value: parseInt(preuser?.value), procurator: form?.name, process: currentProcess?.id } }) 

            // make relation

            await makeRelation( processed?.data?.id, preuser )

            return processed?.data?.id;
        } 
        return false
    }

    const registerPreCreditors = async () => {
        const promises = selectedRepUsers.map(m => saveCreditor(m) )
        return await Promise.all(promises)
    }

    const makeRelation = async (procuser_id, preuser) => {
        
        console.log("MAKE RELATION", preuser)
        if(preuser?.id){
            const saved = await UpdatePreUser({
                data:{
                    process_user: procuser_id
                }
            }, preuser?.id)
            console.log("CREDITOR SAVED", saved)
        }
        
    }

    const saveCreditor = async (preuser) => {
            
        const password = Crypt(`${ new Date().getTime() }`)

        const genarated_email = `${ `${ preuser.name }`?.replace(/ |\./g, '').replace(/[^a-zA-Z0-9 ]/g, '')?.toLowerCase() }@uorak.com`

        const payload = { 
            name: preuser.name,
            username: genarated_email,
            email: genarated_email,
            confirmed: true,
            blocked: false,
            role: 1,
            
            status: false,
            'class': preuser?.classification,
            password: password,
            creditor_member:true,
            rep_users: selectedRepUsers?.map(m => m?.id),
            type: 'creditor'
        } 
        
        const alreadyExists = await relationCreditorBeforeSafe(payload, preuser)
        if(alreadyExists){ return alreadyExists ;}

        const result = await Create({ ...payload }) 

        if(result && !exposeStrapiError(result)){
            const processed = await CreateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(preuser?.value), procurator:form?.name, process: currentProcess?.id, enabled:false } }) 

            // make reverse relation

            await makeRelation( processed?.data?.id, preuser )

            return processed?.data?.id;
        }

        return false;
    }

    const save = async () => {
        
        if(!loading){

            setLoading(true)

            console.log("FORM", form, selectedRepUsers)

            const represented_users = selectedRepUsers?.length ? await registerPreCreditors() : []
            
            if(await relationBeforeSafe(represented_users)){ 
                setLoading(false);
                return ;
            }

            const password = Crypt(`${ new Date().getTime() }`)
    
            setPass(password)
    
            const payload = {
                ...form,
     
                username: form.email?.replace(/ /g,''),
                email: form.email?.replace(/ /g,''),
                confirmed: true,
                blocked: false,
                role: 1,
                
                status: false,
                'class': currentPreUser?.classification,
                type_person: optionsPerson?.find(f => `${f.id}` === `${form?.type}`)?.ref,
                files: files?.filter(f => f)?.map(m => m?.id),
    
                password: password,
                creditor_member:true,
                type: mode,
                rep_users: selectedRepUsers?.map(m => m?.id)
            } 
            
            const result = await Create({ ...payload }) 
    
            if(result && !exposeStrapiError(result)){
                const processed = await CreateProcessUser({ data:{ user_creditor: result?.id, value:parseInt(currentPreUser?.value || 0), process: currentProcess?.id, enabled:false, represented_users } }) 

                await makeRelation( processed?.data?.id, currentPreUser )

                setStep(2)
            }
    
            setLoading(false)
        }
    }

    const confirmSearch = () => {
        if(!selectedRepUsers?.length){
            toast.error("Selecione os credores para continuar")
            return;
        }

        if(mode === "sindicate"){
            navigate(`dashboard/accreditation/${id}/sindicate/sindicate`)
        }else{
            setStep(1)
        }
    }

    const searchCreditor = async () => {
        if(form?.cred?.length > 2){
            setSearching(true)
    
            const result = await ReadUserByName(form?.cred) 
            const assemblyResult = (result?.data || []).filter(f => f?.attributes?.process?.data?.id === currentProcess?.id )?.filter(f => !f?.attributes?.process_user?.data)
            // const normalResult = normalizeStrapiList(assemblyResult)


            // we needs filter here registrared creditors.
            // we also needs add to before save 

            console.log("normalResult", assemblyResult)
            setRepUsers(assemblyResult.map(normalizeStrapiRegister))


    
            setSearching(false)
        }
    }

    const changeFile = (file, ref) => {
        setLoading(true)
        let nfiles = [ ...files ]
        nfiles[ref] = file
        setFiles([ ...nfiles ])
        setTimeout(() => setLoading(false), 1)
    }

    const completeNext = async () => {
        setLoging(true)
        if(pass){
            setFirst(true)
            const result = await DoLogin({ password: pass, identifier: form.email?.replace(/ /g,'') })
            if(result && !exposeStrapiError(result)){
                completeLogin(result)
            }
        } else {
            navigate(`login/${ id }/${ form?.email }`) 
        }
        
        setLoging(false)
    }
    
    const handleForm = async () => {

        console.log("FORMING")

        if(!formValue('terms')){
            toast.error("Aceite os termos para continuar") 
            return;
        }

        if(mode === "creditor"){

            setFinding(true)
                const result = await ReadUserByName(form?.name)
                const assemblyResult = (result?.data || []).find(f => f?.attributes?.process?.data?.id === currentProcess?.id )
            setFinding(false)
    
            if(!assemblyResult){
                toast.error(`${ENUM_MODES[mode]} não encontrado, verifique o nome informado e tente novamente.`) 
                return;
            }

            setCurrentPreUser(normalizeStrapiRegister(assemblyResult))
        }
        setStep(1)
    }

    const completeLogin = (result) => {
        if(result?.user){ setUser(result.user) }
        navigate(`dashboard/vote/${ id }`) 
    } 

    const isSelectedCred = item => {
        return selectedRepUsers?.map(m => m.id)?.includes(item?.id)
    }

    const toggleCred = item => {
        setSelectedRepUsers( 
                isSelectedCred(item) ? 
            selectedRepUsers?.filter(m => m.id !== item?.id) : [ ...selectedRepUsers, item] 
        )
    }

    useEffect(() => {
        setStep(0)
    }, [params])
    
    useEffect(() => {
        init()
    }, [id])
 
    return ( 
        <>  
            <ContainerUnauthenticated keep>
                {
                    closed ? <>
                        <FormTitle>Olá</FormTitle>
                        <FormText>O credenciamento está fechado</FormText> 
                        <Button nospace outline onClick={!closed ? null : () => navigate(`login`)} loading={loading} primary>{ currentProcess?.company_name }</Button>
                        {/* <FormSpacer /> */}
                        {/* <Button primary onClick={() => setStep(1)}>Continuar</Button> */}
                    </> : <>
                        {
                            (!step || `${step}` === `${0}`) && !mode ? <>
                                <FormTitle>Olá</FormTitle>
                                <FormText>Vamos iniciar o seu credenciamento para participar da assembleia:</FormText> 
                                <Button nospace outline loading={loading} primary>{ currentProcess?.company_name }</Button>
                                <FormSpacer />
                                <Button primary onClick={() => setStep(1)}>Continuar</Button>
                            </> : null
                        }
                        {
                            `${step}` === `${1}` && !mode ? <>
                                <FormTitle>Identificação</FormTitle>
                                <FormText>Escolha uma das opções em que você se enquadra</FormText> 
                                <Button nospace outline secondary onClick={() => navigate(`dashboard/accreditation/${id}/creditor`)}>Credor</Button>
                                <Button outline secondary onClick={() => navigate(`dashboard/accreditation/${id}/attorney`)}>Procurador</Button>
                                <Button outline secondary onClick={() => navigate(`dashboard/accreditation/${id}/sindicate`)}>Sindicato</Button>
                            </> : null
                        }
        
                        {
                            `${step}` === `${0}` && mode && mode !== "creditor" && !type ? <>
                                <FormTitle>Dados do credor</FormTitle>
                                <FormText>Pesquise o Nome do credor.</FormText>
        
                                <RowBetween>
                                    {/* <MaskedInput mask={form?.doc?.length < 15 ? "999.999.999-999" : "99.999.999/9999-99"} placeholder="CPF / CNPJ" value={formValue('doc')} onChange={e => changeForm(e?.target?.value, 'doc')} /> */}
                                    <Input placeholder="Informe o nome do credor" value={formValue('cred')} onChange={e => changeForm(e?.target?.value, 'cred')} onSubmitEditing={searchCreditor} />
                                    <Button loading={searching} nospace outline primary onClick={searchCreditor}>Pesquisar</Button>
                                </RowBetween>
                                {
                                    !(repUsers?.length || selectedRepUsers?.length) ? null : <>
                                        <SearchTitle>Marque os credores que você vai representar</SearchTitle>
                                        {
                                            searching ? <LoadCenter><Load /></LoadCenter> : <> 
                                                {
                                                    selectedRepUsers?.map((item, key) => 
                                                        <UploadContent key={key} onClick={() => toggleCred(item)}>
                                                            <Check checked={isSelectedCred(item)} onChange={() => toggleCred(item)} /> { item?.name }
                                                        </UploadContent>
                                                    )
                                                }
                                                {
                                                    repUsers?.filter(f => !isSelectedCred(f))?.map((item, key) => 
                                                        <UploadContent key={key} onClick={() => toggleCred(item)}>
                                                            <Check checked={isSelectedCred(item)} onChange={() => toggleCred(item)} /> { item?.name }
                                                        </UploadContent>
                                                    )
                                                }
                                            </>
                                        }
                                    </>
                                }
        
                                <Button primary onClick={confirmSearch}>Confirmar e continuar</Button>
                            </> : null
                        }
                        {
                            ((`${step}` === `${1}` && mode && mode !== "creditor")||(`${step}` === `${0}` && mode && mode === "creditor")) && !type ? <>
                                <FormTitle>Identificação</FormTitle>
                                <FormText>Escolha uma das opções em que você se enquadra</FormText> 
                                <Button nospace outline secondary onClick={() => navigate(`dashboard/accreditation/${id}/${mode}/pf`)}>Pessoa física</Button>
                                <Button outline secondary onClick={() => navigate(`dashboard/accreditation/${id}/${mode}/pj`)}>Pessoa jurídica</Button>
                            </> : null
                        }
        
                        {
                            `${step}` === `${0}` && mode && type ? <>
                                <FormTitle>Credenciamento do { ENUM_MODES[mode] }</FormTitle>
                                <FormText>Dados pessoais</FormText> 
        
                                <Input placeholder="Nome" value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} /> 
                                <FormSpacer />
                                <MaskedInput mask={"999.999.999-99"} placeholder="CPF" value={formValue('document')} onChange={e => changeForm(e.target.value, 'document')} /> 
                                <FormSpacer />
                                <Input placeholder="E-mail" value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} /> 
                                
                                <FormSpacer />
        
                                <ContentTerms onClick={e => changeForm( !formValue('terms'), 'terms')}>
                                    <Check checked={formValue('terms')} />
                                    <p>
                                        Ao selecionar e continuar, você concorda com nossos <b>Termos de serviço</b> e <b>Política de privacidade</b>
                                    </p>
                                </ContentTerms>
        
                                <Button loading={finding} primary onClick={handleForm}>Continuar</Button>
                            </> : null
                        }
        
                        {
                            mode === "creditor" ? <>
                                {
                                    `${step}` === `${1}` && mode && type === "pf"  ? <>
                                        <FormTitle>Documentos</FormTitle>
                                        <FormText>Envie os documentos necessários para validar seu acesso</FormText> 
                                        
                                        {
                                            loading ? <Load centred /> : <>
                                                <UploadTitle>Documento com foto</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "0")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["0"]?.name ? files?.["0"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Outros documentos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "1")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["1"]?.name ? files?.["1"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                            </>
                                        }
                                        
        
                                        <FormSpacer />
                                        <Button primary loading={loading} onClick={save}>Finalizar</Button>
                                    </> : null
                                }
        
                                {
                                    `${step}` === `${1}` && mode && type === "pj"  ? <>
                                        <FormTitle>Documentos</FormTitle>
                                        <FormText>Envie os documentos necessários para validar seu acesso</FormText> 
                                        
                                        {
                                            loading ? <Load centred /> : <>
                                                <UploadTitle>Contrato social / Atos constitutivos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "2")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["2"]?.name ? files?.["2"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Outros documentos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "3")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["3"]?.name ? files?.["3"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                            </>
                                        }
                                        
        
                                        <FormSpacer />
                                        <Button primary loading={loading} onClick={save}>Finalizar</Button>
                                    </> : null
                                }
                            </> : null
                        }
        
                        {
                            mode === "attorney" ? <>
                                {
                                    `${step}` === `${1}` && mode && type === "pj"  ? <>
                                        <FormTitle>Documentos</FormTitle>
                                        <FormText>Envie os documentos necessários para validar seu acesso</FormText> 
                                        
                                        {
                                            loading ? <Load centred /> : <>
                                                <UploadTitle>Documento pessoal do (a) credor (a)</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "4")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["4"]?.name ? files?.["4"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Procuração</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "5")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["5"]?.name ? files?.["5"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Documento  pessoal do (a) credor (a)</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "6")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["6"]?.name ? files?.["6"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Documento do advogado (a)</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "7")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["7"]?.name ? files?.["7"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Outros documentos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "8")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["8"]?.name ? files?.["8"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                            </>
                                        }
        
                                        <FormSpacer />
                                        <Button primary loading={loading} onClick={save}>Finalizar</Button>
                                    </> : null
                                }
        
                                {
                                    `${step}` === `${1}` && mode && type === "pf"  ? <>
                                        <FormTitle>Documentos</FormTitle>
                                        <FormText>Envie os documentos necessários para validar seu acesso</FormText> 
                                        
                                        {
                                            loading ? <Load centred /> : <>
                                                <UploadTitle>Contrato social / Atos constitutivos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "9")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["9"]?.name ? files?.["9"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Procuração</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "10")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["10"]?.name ? files?.["10"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Substabelecimento</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "11")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["11"]?.name ? files?.["11"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Documento com foto do advogado (a)</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "12")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["12"]?.name ? files?.["12"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                                
                                                <UploadTitle>Outros documentos</UploadTitle>
                                                <UploadFile onChange={file => changeFile(file, "13")}>
                                                    <UploadContent>
                                                        {
                                                            files?.["13"]?.name ? files?.["13"]?.name : <>
                                                                <UploadContentIcon />
                                                                Adicione arquivo
                                                            </>
                                                        }
                                                    </UploadContent>
                                                </UploadFile>
                                            </>
                                        }
        
                                        <FormSpacer />
                                        <Button primary loading={loading} onClick={save}>Finalizar</Button>
                                    </> : null
                                }
                            </> : null
                        }
        
                        {
                            `${step}` === `${1}` && mode && type === "sindicate" ? <>
                                <FormTitle>Documentos</FormTitle>
                                <FormText>Envie os documentos necessários para validar seu acesso</FormText> 
                                {
                                    loading ? <Load centred /> : <>
                                        <UploadTitle>Procuração para representar o sindicato</UploadTitle>
                                        <UploadFile onChange={file => changeFile(file, "14")}>
                                            <UploadContent>
                                                {
                                                    files?.["14"]?.name ? files?.["14"]?.name : <>
                                                        <UploadContentIcon />
                                                        Adicione arquivo
                                                    </>
                                                }
                                            </UploadContent>
                                        </UploadFile>
                
                                        <UploadTitle>Documento com foto do representante</UploadTitle>
                                        <UploadFile onChange={file => changeFile(file, "15")}>
                                            <UploadContent>
                                                {
                                                    files?.["15"]?.name ? files?.["15"]?.name : <>
                                                        <UploadContentIcon />
                                                        Adicione arquivo
                                                    </>
                                                }
                                            </UploadContent>
                                        </UploadFile>
                                        
                                        <UploadTitle>Lista dos credores assistidos pelo sindicato</UploadTitle>
                                        <UploadFile onChange={file => changeFile(file, "16")}>
                                            <UploadContent>
                                                {
                                                    files?.["16"]?.name ? files?.["16"]?.name : <>
                                                        <UploadContentIcon />
                                                        Adicione arquivo
                                                    </>
                                                }
                                            </UploadContent>
                                        </UploadFile>
                                        
                                        <UploadTitle>Outros documentos</UploadTitle>
                                        <UploadFile onChange={file => changeFile(file, "17")}>
                                            <UploadContent>
                                                {
                                                    files?.["17"]?.name ? files?.["17"]?.name : <>
                                                        <UploadContentIcon />
                                                        Adicione arquivo
                                                    </>
                                                }
                                            </UploadContent>
                                        </UploadFile>
                                    </>
                                }
        
                                <FormSpacer />
                                <Button primary loading={loading} onClick={save}>Finalizar</Button>
                            </> : null
                        }
        
                        {
                            `${step}` === `${2}` && mode && type  ? <>
                                <SuccessContent>
                                    <SuccessIcon />
                                    <FormTitle spaced centred>Sucesso!</FormTitle>
                                    <FormText  centred>Credenciamento concluído com sucesso!</FormText> 
                                    <FormText>
                                        Suas informações e documentos estão em processo de análise. Assim que a análise for concluída, você receberá novas instruções no e-mail cadastrado. Fique atento!    
                                    </FormText>                         
                                    <FormSpacer />
                                    <Button primary loading={loging} onClick={completeNext}>Fechar</Button>
                                </SuccessContent>
                            </> : null
                        }
                    </>
                }
            </ContainerUnauthenticated> 
        </>
    );
}