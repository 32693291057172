import styled from 'styled-components'  
import { statusColors } from 'ui/theme-color';

export const HeaderActionItem = styled.div.attrs({ 
})`
    border-left: 1px solid ${ props => props.theme.palette.colors.shadow };
    padding: 0 0 0 12px;
`;

export const ButtonButtonIconPlay = styled.img.attrs({ 
    src:'/icons/play.svg'
})`
`;

export const CardVote = styled.div.attrs({ 
})`
    padding: 16px;
    background: ${ props => props.theme.palette.colors.white };
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;
`;

export const CardVoteTitle = styled.div.attrs({ 
})`
    font-size: 16px;
    font-weight: 600;
    color: ${ props => props.theme.palette.primary.main };
`;

export const CardVoteActions = styled.div.attrs({ 
})`
    display: flex; 
    align-items: center;
    gap: 12px;

    font-size: 14px;
    color: ${ props => props.theme.palette.colors.text };
    height: 45px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const CardVoteActionsIcon = styled.img.attrs({ 
    src: '/icons/chevron.svg'
})`
    ${
        p => p.open ? `
            transform: rotate(180deg);
        ` : ``
    }
`;

export const CardVoteActionsContainer = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 12px;
`;

export const CardVoteAction = styled.div.attrs({ 
})`
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const CardVoteTable = styled.div.attrs({ 
})`
    padding: 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.white };
`;

export const VoteItem = styled.div.attrs({ 
})`
    padding: 4px 16px;
    border-radius: 16px;
    color: ${ props => props.theme.palette.colors.white };
    background: ${ props => props.theme.palette.colors?.[statusColors?.[props.status]] };
    width: 100%;
    max-width: 100px;
    margin: 0 0 0 auto;
    text-align: center;
`;