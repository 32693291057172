import React, { useState } from "react";   
 
import DashboardSide from "../Side";
import { 
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    CogIcon
} from "./styled"; 
import Button from "components/Form/Button";
import DashboardHeaderMenu from "../HeaderMenu";
import { MobileContent } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function DashboardHeader({ noside }){  
    
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    return ( 
        <> 
            <DashboardHeaderContainer>
                {
                    !noside ? 
                        <MobileContent>
                            <DashboardHeaderActionIcon src={`/icons/logo-icon.svg`} alt="menu-icon" /> 
                        </MobileContent>
                    : <DashboardHeaderActionIcon src={`/icons/logo.svg`} alt="menu-icon" onClick={() => navigate("dashboard")} /> 
                }
                <DashboardHeaderMenu>
                    <Button nospace primary>
                        <CogIcon />
                    </Button>
                </DashboardHeaderMenu>
            </DashboardHeaderContainer> 
        </>
    );
}