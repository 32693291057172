import React, { createRef, useContext, useEffect, useRef, useState } from "react";  
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import {  
    DashboardTitle,
    ContentCard,
    ContentCardTable,
    ContentCardItem,
    ContentCardActions,
    DashboardText,
    HeaderRow,
    HeaderContent,
    HeaderBack,

    HeaderActions,
    LabelCredential,
    LabelCredentialText,
    HeaderActionItem,
    ButtonButtonIconPlay,

    ContentTabs,
    TabItem,
    TabItemText,

    ButtonButtonIconPdf,
    ToggleItem,
    ChangeOption,
    ProcuratorContent,
    ButtonButtonIconCopy,

    RowCenter,
    RowCenterItem,
    ButtonIconAdd,




ContentRowUpload,
ContentUpload,
ContentUploadIcon,
ContentRemove,
ContentRemoveIcon,
ContentRowUploadUp,
ContentAddIcon,
ContentRowGap,
ContentRowGapItem
} from "./styled";

import { Row, Col } from "reactstrap";
import { optionsClassification, optionsCreditorStatus, optionsEdictalStatus, optionsStatus } from "utils/options";

import ContainerAuthenticated from "containers/Authenticated";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import BasicTable from "components/Form/Table";

import { 
    ItemIcon,
    Load,
    RowBetween, 
    RowBetweenItem 
} from "ui/styled";
import Button from "components/Form/Button";
import Toggle from "components/Form/Toggle";
import RowVote from "components/Dashboard/RowVote";
import CardMore from "components/Dashboard/CardMore";
import RowVoteResult from "components/Dashboard/RowVoteResult";
import Core from "components/Form/Core";
import UploadFile from "components/Form/UploadFile";
import { Create, ReadOne, Update } from "services/processes";
import { CoreContext } from "context/CoreContext";
import { maskToCurrency, parseCurrency, parseDatestringBRUS } from "utils/parsers";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { toast } from "react-toastify";
import { Read } from "services/process-users";
import { Read as ReadPreUsers } from "services/pre-users";
import { Update as UpdateUser } from "services/users";
import moment from "moment/moment";

export default function DashboardCreateProcess(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams();

    const [ loading, setLoading ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(0)
    const [ register, setRegister ] = useState(null)
    
    const [credentialLink, setCredentialLink] = useState(null)

    const { user } = useContext(CoreContext)
    
    const [uploadsRef, setUploadsRef] = useState([
        createRef()
    ])
    
    const refForm = useRef()
    const refFormLink = useRef()

    const [files, setFiles] = useState([])
    const [filter, setFilter] = useState({})

    const formData = [
        { placeholder:"Nome da empresa", ref:"company_name", quarter:true },
        { placeholder:"Número do processo", ref:"process_number", quarter:true },
        { placeholder:"Data", ref:"date", mask:"99/99/9999", quarter:true },
        { placeholder:"Hora", ref:"hour", mask:"99:99", quarter:true },

        { placeholder:"Valor (R$)", ref:"value", mask:"money"  },
        { placeholder:"Início do credenciamento", ref:"begins_register", mask:"99/99/9999 - 99:99"  },
        { placeholder:"Início da AGC", ref:"begins_agc", mask:"99/99/9999 - 99:99"  },
        
        { placeholder:"Administrador judicial", ref:"juridic_admin", half:true  },
        { placeholder:"Status do edital de convocação", ref:"edital_status", options:optionsStatus, half:true  },
    ]

    const formDataLink = [
        { placeholder:"Link da assembléia", ref:"link", full:true },
    ]

    const formDataUpload = [
        // { placeholder:"Nome do documento", ref:"document_name", half:true },
        { 
            custom: row => <>
                <ContentRowUpload>
                    <ContentRowUploadUp>
                        <UploadFile onChange={file => changeFile(file, row)}>
                            <ContentUpload>
                                <ContentUploadIcon />
                                { files?.[row] ? files?.[row]?.name : "Adicione arquivo"}
                            </ContentUpload>
                        </UploadFile>
                    </ContentRowUploadUp>
                    <ContentRemove onClick={() => removeRow(row)}>
                        <ContentRemoveIcon />
                    </ContentRemove>
                </ContentRowUpload>
            </>, 
            full:true 
        },
    ]

    const tabs = [
        { title:"Informações do processo" },
        !id ? null : { title:"Credores" }
    ].filter(f => f)
    
    const addRow = () => {
        setUploadsRef([
            ...uploadsRef,
            createRef()
        ])
    }

    const removeRow = row => { 
        if(row !== 0){
            setFiles(files?.filter((f,k) => k !== row ))
            setUploadsRef(uploadsRef?.filter((f,k) => k !== row ))
        }
    }

    // Table

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const columns = [
        { title:'Relação de credores do processo', ref:'creditor' },
        { title:'Classe', ref:'classification' },
        { title:'Valor R$', ref:'value' },

        { 
            title:'Procuradores', 
            renderCell: ({ row }) => <>
                <ProcuratorContent onClick={() => navigate(`dashboard/creditor/create/${id}/${ row?.processUserId }/${ row?.id }`)}>
                    { row?.procurator }
                    <ChangeOption>
                        Alterar
                    </ChangeOption>
                </ProcuratorContent>
            </>
        },

        { title:'Email', ref:'email' },
        { title:'Telefone', ref:'phone' },
        // { title:'Status', ref:'status' },

        // { 
        //     title:'Habilitação', 
        //     renderCell: ({ row }) => loading ? <Load /> : <ToggleItem>
        //         <Toggle checked={row?.enabled} onChange={() => toggleEnabled(row)} />
        //     </ToggleItem> 
        // }, 
        
    ] 

    const columnsPreUser = [
        { title:'Credor', ref:'name' },
        { title:'Classe', ref:'classification' },
        { title:'Valor R$', ref:'value' },
        // { title:'Procurador', ref:'procurator' },
        // { title:'Status', ref:'status' },
    ] 

    const [rows, setRows] = useState([])
    const [rowsPreUser, setRowsPreUser] = useState([])
    
    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const filterClassificatino = item => {
        return ( !filter?.classification || item?.classification === optionsClassification?.find(f => `${f.id}` === `${filter?.classification}` )?.title )
    }
    
    const filterEnabled = item => {
        return ( !filter?.enabled || item?.status === optionsCreditorStatus?.find(f => `${f.id}` === `${filter?.enabled}` )?.title )
    }

    const save = async () => {
        if(!loading){
            setLoading(true)
    
            const form = refForm?.current?.getForm()
            const formLink = refFormLink?.current?.getForm()
    
            const datetime = form?.date ? `${parseDatestringBRUS(form?.date)}T${form?.hour}` : null ;
            const start_accreditation = form?.begins_register ? `${parseDatestringBRUS(form?.begins_register?.split(" - ")?.[0])}T${form?.begins_register?.split(" - ")?.[1]}` : null ;
            const start_agc = form?.begins_agc ? `${parseDatestringBRUS(form?.begins_agc?.split(" - ")?.[0])}T${form?.begins_agc?.split(" - ")?.[1]}` : null ;
    
            const payload = {
    
                number_process: form?.process_number,
    
                notice: optionsStatus?.find(f => `${f.id}` === `${form?.edital_status}`)?.ref, // published | not_published
                status:"scheduled", // scheduled | finished
                value: form?.value,
                company_name: form?.company_name,
                
                datetime,
                start_accreditation,
                start_agc,
    
                judicial_administrator: `${form?.juridic_admin}`,
    
                link_videoconference: formLink?.link,
    
                user: user?.id,
                files: files?.map(m => m.id)
            }
            
            const result = id ? await Update({ data: payload }, id) : await Create({ data: payload })
    
            if(result && !exposeStrapiError(result)){
                toast.success("Salvo com sucesso!")
                history.goBack();
            }
    
            setLoading(false)
        }
    }

    const changeFile = (file, key) => {
        const nfiles = [...files]
        nfiles[key] = file
        setFiles([ ...nfiles ])
    }

    const init = async () => {

        setLoading(true)

        if(id){

            const result = await ReadOne(id)
            if(result && !exposeStrapiError(result)){
                const normalResult = normalizeStrapiRegister(result)

                // console.log("normalResult", normalResult)

                const nextresult = {
                    ...normalResult,
                    company_name: normalResult?.company_name,
                    process_number: normalResult?.number_process,
                    date: !normalResult?.datetime ? "" : moment(normalResult?.datetime)?.format('L'),
                    hour: !normalResult?.datetime ? "" : moment(normalResult?.datetime)?.format('hh:mm'),
                    value: normalResult?.value,
                    begins_register: !normalResult?.start_accreditation ? "" : moment(normalResult?.start_accreditation)?.format('L - hh:mm'),
                    begins_agc: !normalResult?.start_agc ? "" : moment(normalResult?.start_agc)?.format('L - hh:mm'),
                    juridic_admin: normalResult?.judicial_administrator,
                    edital_status: optionsStatus?.find(f => f.ref === normalResult?.notice)?.id,
                    link: normalResult?.link_videoconference
                }

                setFiles( normalResult?.files ? normalResult?.files : [] )
                setUploadsRef( normalResult?.files ? normalResult?.files?.map(m => createRef() ) : [createRef()] )
                setCredentialLink( normalResult?.link )
                setRegister(nextresult)
            }

            const users = await Read(id)
            if(users?.data?.length){
                const normalUsers = normalizeStrapiList(users)
                const nextRows = normalUsers?.map(item => {
                    const user = !item?.user_creditor ? null : normalizeStrapiRegister(item?.user_creditor)
                    const user_procurator = !user?.user_prosecutor ? null : normalizeStrapiRegister(user?.user_prosecutor)
    
                    console.log("user", user)
    
                    return {
                        id: user?.id, 
                        processUserId: item?.id,
                        creditor: user?.name, 
                        type: user?.type, 
                        classification: optionsClassification?.find(f => f.ref === user?.['class'])?.title, 
                        classification_id: optionsClassification?.find(f => f.ref === user?.['class'])?.id, 

                        value: parseCurrency(item?.value), 
                        procurator: item?.procurator, 
                        // permission: true, 

                        email: user?.email, 
                        phone: user?.phone, 
                        status: user?.status ? "Habilitado" : "Desabilitado",
                        enabled: user?.status
                    }
                }).filter(f => f.type === 'creditor')
                setRows(nextRows)
            }

            const pre_users = await ReadPreUsers(id)
            if(pre_users?.data?.length){
                const normalPreUsers = normalizeStrapiList(pre_users)
                const nextRows = normalPreUsers?.map(item => {    
    
                    return {
                        ...item,
                        value: parseCurrency(item?.value),
                        classification: optionsClassification?.find(f => f.ref === item?.['classification'])?.title, 
                        classification_id: optionsClassification?.find(f => f.ref === item?.['classification'])?.id, 
                        status: item?.license ? "Habilitado" : "Desabilitado",
                    }

                })
                .sort((a, b) => a.name.localeCompare(b.name) )
                .sort((a, b) => a.classification_id - b.classification_id )

                setRowsPreUser(nextRows)
            }
        }


        setLoading(false)

    }

    const toggleEnabled = async (item) => {
        if(!loading){
            setLoading(true)
            const result = await UpdateUser({ status: !item?.enabled, blocked: !!item?.enabled }, item?.id)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setLoading(false)
        }
    }

    useEffect(() => { init() ;}, [])

    const clearFilter = () => {
        setForm({})
        setFilter({})
    }

    const applyFilter = () => {
        setFilter(form)
    }

    const massUpload = async (file) => {
        // ...
        setTimeout(() => {
            init()
        }, 10)
    }

    const copyToClipboard = () => {
        const ct = document.getElementById("copyText")
            ct.select();
        document.execCommand("copy");
        toast.success("Copiado");
    }

    // console.log("register", register)

    return ( 
        <>
            <ContainerAuthenticated page={0}> 
                <Row>
                    <Col> 
                        <HeaderRow>
                            <HeaderBack onClick={history.goBack} />
                            <HeaderContent>
                                <DashboardTitle nomargin> Cadastro do Processo </DashboardTitle> 
                            </HeaderContent>
                            
                            <HeaderActions>
                                {
                                    activeTab === 0 ? <>
                                        {
                                            !credentialLink ? null : <>
                                                <LabelCredential>
                                                    <LabelCredentialText>Link de credenciamento | { credentialLink }</LabelCredentialText>
                                                </LabelCredential>
                                                <HeaderActionItem>
                                                    <Button nospace outline primary onClick={copyToClipboard}>
                                                        <ButtonButtonIconCopy />
                                                    </Button>
                                                </HeaderActionItem>
                                            </>
                                        }
                                        <HeaderActionItem>
                                            <Button nospace primary loading={loading} onClick={save}>
                                                Salvar
                                            </Button>
                                        </HeaderActionItem>
                                    </> : null
                                }
                                {
                                    activeTab === 1 ? <>
                                        <Button nospace primary loading={loading} onClick={save}>
                                            Salvar
                                        </Button>
                                    </> : null
                                }
                                
                            </HeaderActions>
                        </HeaderRow>

                        <ContentTabs>
                            {
                                tabs?.map((item, key) => 
                                    <TabItem active={key === activeTab} key={key} onClick={() => setActiveTab(key)}>
                                        <TabItemText>{ item?.title }</TabItemText>
                                    </TabItem>
                                )
                            }
                        </ContentTabs>

                        {
                            activeTab === 0 ? <> 

                                <ContentCardTable>
                                    <DashboardTitle> Informações do processo </DashboardTitle> 
                                    <Core formItems={formData} register={register} ref={refForm} />
                                </ContentCardTable>
                                
                                <ContentCardTable>
                                    <DashboardTitle> Insira o link para o credor participar da assembléia  </DashboardTitle> 
                                    <Core formItems={formDataLink} register={register} ref={refFormLink} />
                                </ContentCardTable>
                                
                                <ContentCardTable>
                                    <DashboardTitle> Upload dos documentos do processo </DashboardTitle> 
                                    {
                                        uploadsRef?.map((pref, kref) => 
                                            <Core key={kref} row={kref} formItems={formDataUpload} register={register} ref={pref} />
                                        )
                                    }
                                    
                                    <RowCenter>
                                        <RowCenterItem>
                                            <Button outline secondary onClick={addRow}>
                                                <ButtonIconAdd />
                                                Novo
                                            </Button>
                                        </RowCenterItem>
                                    </RowCenter>

                                </ContentCardTable>

                            </> : null
                        }

                       
                        {
                            activeTab === 1 ? <> 

                                <ContentCard>

                                    <ContentCardItem>
                                        <Input placeholder="Pesquise procurador ou credor" value={searchExpression} onChange={e => setSearchExpression(e.target.value)} /> 
                                    </ContentCardItem>
                                    <ContentCardItem medium>
                                        <Select placeholder="Classificação" options={optionsClassification} value={formValue('classification')} onChange={e => changeForm(e, 'classification')} param={"title"} /> 
                                    </ContentCardItem>
                                    {/* 
                                        <ContentCardItem small>
                                            <Select placeholder="Status" options={optionsStatus} value={formValue('status')} onChange={e => changeForm(e, 'status')} param={"title"} /> 
                                        </ContentCardItem> 
                                    */}
                                    <ContentCardItem small>
                                        <Select placeholder="Status" options={optionsCreditorStatus} value={formValue('enabled')} onChange={e => changeForm(e, 'enabled')} param={"title"} /> 
                                    </ContentCardItem>
                                    
                                    <ContentCardActions>
                                        <Button nospace primary link onClick={clearFilter}>Limpar</Button>
                                        <Button nospace primary onClick={applyFilter}>Filtrar</Button>
                                    </ContentCardActions>

                                </ContentCard>

                                <ContentCardTable>
                                    <RowBetween>
                                        <DashboardTitle> Lista de credores </DashboardTitle> 
                                        <ContentRowGap>
                                            <ContentRowGapItem>
                                                <UploadFile mass={id} onChange={massUpload}>
                                                    <Button nospace secondary outline>
                                                        Carregar lista de credores
                                                    </Button>
                                                </UploadFile>
                                            </ContentRowGapItem>
                                            <ContentRowGapItem small>
                                                <Button nospace secondary outline onClick={() => navigate(`dashboard/creditor/create/${id}`)}>
                                                    <ContentAddIcon />
                                                    Novo
                                                </Button>
                                            </ContentRowGapItem>
                                        </ContentRowGap>
                                    </RowBetween>
                                    { !rows?.length ? null : <BasicTable loading={loading} columns={columns} rows={rows?.filter(filterExpression)?.filter(filterClassificatino)?.filter(filterEnabled)} /> }
                                    <DashboardTitle> &nbsp; </DashboardTitle> 
                                    <DashboardTitle> Lista de credores pré-cadastrados </DashboardTitle> 
                                    { !rowsPreUser?.length ? null : <BasicTable loading={loading} columns={columnsPreUser} rows={rowsPreUser?.filter(filterExpression)} /> }
                                </ContentCardTable>

                            </> : null
                        }

                    </Col> 
                </Row>
            </ContainerAuthenticated> 
            <input type={"text"} style={{ position: 'absolute', opacity:0, height: 0, zIndex:-2 }} value={credentialLink} id="copyText" />
        </>
    );
}