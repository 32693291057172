import { GET, POST, PUT, DELETE } from './api'

const table = "processes"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async (id) => {
    return await GET(`/${ table }?filters[user]=${id}&populate=*&_limit=-1&pagination[pageSize]=1000`, true);
    // return await GET(`/${ table }`, true);
}

export const ReadByCode = async (id) => {
    return await GET(`/${ table }?filters[link]=${id}&populate=*&_limit=-1&pagination[pageSize]=1000`, true);
    // const all = await GET(`/${ table }?populate=*&_limit=-1&pagination[pageSize]=1000`, true);

    // const byCode = (all?.data||[])?.filter(f => {
    //     return f?.attributes?.link === id
    // })
    // return byCode
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
} 
 