import React, { useState } from "react";  

import {  
    
    CardVote,
    CardVoteTitle,
    CardVoteActions,
    CardVoteActionsIcon,
    CardVoteContainer,
    CardVoteBody
    
} from "./styled"; 

export default function CardMore({ title, children, openToPrint }){  
        
    const [ open, setOpen ] = useState(false)  
 
    return ( 
        <> 
            <CardVoteContainer openToPrint={openToPrint}>
                <CardVote onClick={() => setOpen(!open)}>
                    <CardVoteTitle>{ title }</CardVoteTitle>
                    <CardVoteActions>
                        <CardVoteActionsIcon open={open} />
                    </CardVoteActions>
                </CardVote>
                    { 
                        open || openToPrint ? 
                            <CardVoteBody>
                                { children }
                            </CardVoteBody>
                        : null 
                     }
            </CardVoteContainer>
        </>
    );
}