import styled from 'styled-components'   
  

export const DashboardMenuContainer = styled.div.attrs({ 
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${ props => props.theme.palette.colors.shadow };
`;

export const DashboardMenu = styled.div.attrs({ 
    className:'menu-contant'
})`           
    max-width: 252px;
    min-width: 252px;
    background: ${ props => props.theme.palette.colors.white };
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media(max-width: 991px){
        max-width: 80px;
        min-width: 80px;
    }

    @media(max-width: 991px){
        display: none;
    }
`;

export const DashboardMenuHeader = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.white };
    cursor: pointer;

    background: ${ props => props.theme.palette.primary.main };
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({ 
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    padding: 27px 25px;
    margin-bottom: 28px; 

    @media(max-width: 991px){
        padding: 10px 4px;
    }
`;

export const DashboardMenuHeaderUserImage = styled.img.attrs({ 
    src:'/icons/logo-icon.svg'
})`
    margin: 0 auto;
    @media(max-width: 991px){
        max-width: 100%;
    }
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({ 
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({ 
})`           
    padding: 12px 16px;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.grey };
    cursor: pointer; 

    border-radius: 4px;
    margin: 4px 16px;

    &:hover{
        text-decoration: underline;
    }
    
    ${
        props => props.active ? `
            background: ${ props.theme.palette.primary.main };
            font-size: 15px;
            font-weight: bold;
            color: ${ props.theme.palette.colors.white };
        ` : ``
    }

    @media(max-width: 991px){
        font-size: 8px;
        padding: 12px 2px;
        text-align: center;
    }
`;

export const DashboardMenuContent = styled.div.attrs({ 
})` 
    flex:1;
`;

export const DashboardMenuFooter = styled.div.attrs({ 
})`
    padding: 20px;
`;

export const DashboardVersionContent = styled.div.attrs({ 
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({ 
})`
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    text-align: center; 
`;
