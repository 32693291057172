import React from "react";

import { 
    ModalContainer, 
    ModalContainerBody, 
    ModalContainerBodyContainer
} from "./styled";

export default function ModalWrapper({ children }){
    
    return (
        <>
            <ModalContainer>
                <ModalContainerBodyContainer>
                    <ModalContainerBody>
                        { children }
                    </ModalContainerBody>
                </ModalContainerBodyContainer>
            </ModalContainer>
        </>
    )
}