import React, { useContext, useEffect, useState } from "react";  
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

import {  
    DashboardTitle,
    ContentCard,
    ContentCardTable,
    ContentCardItem,
    ContentCardActions,
    DashboardText,
    HeaderRow,
    HeaderContent,
    HeaderBack,

    HeaderActions,
    LabelCredential,
    LabelCredentialText,
    HeaderActionItem,
    ButtonButtonIconPlay,

    ContentTabs,
    TabItem,
    TabItemText,

    ButtonButtonIconPdf,
    ToggleItem,
    ChangeOption,
    ProcuratorContent,
    RowOptions,
    ContainerHideTab
} from "./styled";

import { Row, Col } from "reactstrap";
import { optionsAssembleMassActions, optionsBoolean, optionsClassification, optionsCreditorStatus, optionsStatus } from "utils/options";

import ContainerAuthenticated from "containers/Authenticated";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import BasicTable from "components/Form/Table";

import { 
    ItemIcon,
    Load,
    LoadCenter,
    RowBetween, 
    RowBetweenItem 
} from "ui/styled";
import Button from "components/Form/Button";
import Toggle from "components/Form/Toggle";
import RowVote from "components/Dashboard/RowVote";
import CardMore from "components/Dashboard/CardMore";
import RowVoteResult from "components/Dashboard/RowVoteResult";

import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";

import { ReadOne, Update as UpdateProcess } from "services/processes";
import { Read, Update, Delete as DeleteProcessUser } from "services/process-users";
import { Delete, Read as ReadSchedules } from "services/process-schedules";
import { Update as UpdateUser } from "services/users";

import moment from "moment/moment";
import { parseCurrency } from "utils/parsers";
import { CoreContext } from "context/CoreContext";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";

export default function DashboardAssemblyDetails(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { id } = useParams()

    const { setModal, openToPrint, setOpenToPrint } = useContext(CoreContext)

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ starting, setStarting ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ massing, setMassing ] = useState(false)
    const [ register, setRegister ] = useState(null)
    
    const [ activeTab, setActiveTab ] = useState(0)

    const [ votationSchedules, setVotationSchedules ] = useState([])
    const [ loadingSchedule, setLoadingSchedule ] = useState(false)

    const [ selected, setSelected ] = useState([])
    const [filter, setFilter] = useState({})

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const columns = [
        { title:'Relação de credores', ref:'creditor' },
        { title:'Classificação de Crédito', ref:'classification' },
        { title:'Valor R$', ref:'value' },

        { 
            title:'Procuradores', 
            renderCell: ({ row }) => <>
                <ProcuratorContent onClick={() => navigate(`dashboard/creditor/create/${id}/${ row?.processUserId }/${ row?.id }`)}>
                    { row?.procurator }
                    <ChangeOption>
                        Alterar
                    </ChangeOption>
                </ProcuratorContent>
            </> 
        },
        { 
            title:'Habilitação', 
            renderCell: ({ row }) => <ToggleItem>
                <Toggle checked={row?.enabled} onChange={() => toggleEnabled(row)} />
            </ToggleItem> 
        }, 
        { 
            title:'Presença', 
            renderCell: ({ row }) => <ToggleItem>
                <Toggle checked={row?.presence} onChange={() => togglePresence(row)} />
            </ToggleItem> 
        }, 
    ] 

    const [rows, setRows] = useState([  ])

    const tabs = [
        { title:"Presença e habilitação" },
        { title:"Votações" },
        { title:"Relatório final" },
    ]

    const toggleEnabled = async (item) => {
        if(!loading){
            setLoading(true)
            const result = await Update({ data:{ enabled: !item?.enabled }}, item?.processUserId)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setLoading(false)
        }
    }

    const togglePresence = async (item) => {
        if(!loading){
            setLoading(true)
            const result = await Update({ data:{ presence: !item?.presence } }, item?.processUserId)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setLoading(false)
        }
    }

    const toggleOpenAccreditation = async () => {
        if(!loading){
            setLoading(true)
            const result = await UpdateProcess({ data:{ in_accreditation: !register?.in_accreditation } }, id)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setLoading(false)
        }
    }

    const scheduleAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await UpdateProcess({ data:{ status: "scheduled" } }, id)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setStarting(false)
        }
    }

    const startAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await UpdateProcess({ data:{ status: "running" } }, id)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setStarting(false)
        }
    }

    const completeAssemble = async () => {
        if(!starting){
            setStarting(true)
            const result = await UpdateProcess({ data:{ status: "finished" } }, id)
            if(result && !exposeStrapiError(result)){
                init();
            }
            setStarting(false)
        }
    }

    const massAction = async (type) => {
        setMassing(true)
        const promises = selected?.map( item => {
            if(`${type}` === `1`) return Update({ data:{ enabled: true } }, item?.processUserId);
            if(`${type}` === `2`) return Update({ data:{ presence: true } }, item?.processUserId);
            if(`${type}` === `3`) return DeleteProcessUser(item?.processUserId);
        } )
        const results = await Promise.all(promises)
        console.log("results", results)
        init()
        setMassing(false)
    }

    const filterFilters = item => {
        return ( !filter?.classification || item?.classification === optionsClassification?.find(f => `${f.id}` === `${filter?.classification}` )?.title )
            && ( !filter?.enabled || item?.status === optionsCreditorStatus?.find(f => `${f.id}` === `${filter?.enabled}` )?.title )
            && ( !filter?.presence || item?.presence === optionsBoolean?.find(f => `${f.id}` === `${filter?.presence}` )?.ref )
            && ( !filter?.voted || item?.voted === optionsBoolean?.find(f => `${f.id}` === `${filter?.voted}` )?.ref )
    }

    const clearFilter = () => {
        setForm({})
        setFilter({})
    }

    const applyFilter = () => {
        setFilter(form)
    }

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const init = async () => {

        setLoading(true)

        if(id){

            const result = await ReadOne(id)
            if(result && !exposeStrapiError(result)){
                const normalResult = normalizeStrapiRegister(result)

                // console.log("normalResult", normalResult)

                const nextresult = {
                    ...normalResult,
                    company_name: normalResult?.company_name,
                    process_number: normalResult?.number_process,
                    date: !normalResult?.datetime ? "" : moment(normalResult?.datetime)?.format('L'),
                    hour: !normalResult?.datetime ? "" : moment(normalResult?.datetime)?.format('hh:mm'),
                    value: normalResult?.value,
                    begins_register: !normalResult?.start_accreditation ? "" : moment(normalResult?.start_accreditation)?.format('L - hh:mm'),
                    begins_agc: !normalResult?.start_agc ? "" : moment(normalResult?.start_agc)?.format('L - hh:mm'),
                    juridic_admin: normalResult?.judicial_administrator,
                    edital_status: optionsStatus?.find(f => f.ref === normalResult?.notice)?.id,
                    link: normalResult?.link_videoconference
                }

                // setFiles( normalResult?.files ? normalResult?.files : [] )
                // setUploadsRef( normalResult?.files ? normalResult?.files?.map(m => createRef() ) : [createRef()] )
                // setCredentialLink( normalResult?.link )
                setRegister(nextresult)
            }

            const users = await Read(id)
            if(users?.data?.length){
                const normalUsers = normalizeStrapiList(users)
                const nextRows = normalUsers?.map(item => {
                    const user = !item?.user_creditor ? null : normalizeStrapiRegister(item?.user_creditor)
                    const user_procurator = !user?.user_prosecutor ? null : normalizeStrapiRegister(user?.user_prosecutor)
    
                    // console.log("user", user)
    
                    return {
                        id: user?.id, 
                        processUserId: item?.id,
                        creditor: user?.name, 
                        classification: optionsClassification?.find(f => f.ref === user?.['class'])?.title, 
                        value: parseCurrency(item?.value), 
                        procurator: item?.procurator, 
                        // permission: true, 
                        email: user?.email, 
                        phone: user?.phone, 
                        type: user?.type, 
                        // status: user?.status ? "Habilitado" : "Desabilitado",
                        // enabled: user?.status,
                        // permission: user?.status,
                        
                        status: item?.enabled ? "Habilitado" : "Desabilitado",
                        enabled: item?.enabled,
                        permission: item?.enabled,
                        presence: !!item?.presence,
                        voted: !!item?.voted,
                    }
                })?.filter(f => f.type === 'creditor')
                console.log('setRows', nextRows)
                setRows(nextRows)
            } else { setRows([]) ;}

            initSchedule()
        }

        setLoading(false)
    }
  
    const initSchedule = async () => {

        setLoadingSchedule(true)

        if(id){
            const schedules = await ReadSchedules(id)
            if(schedules?.data?.length){
                const normalSchedules = normalizeStrapiList(schedules)
                const nextRows = normalSchedules?.map(item => {
                    const process = normalizeStrapiRegister(item?.process)  
                    return {
                        ...item,
                        process
                    }
                })
                setVotationSchedules(nextRows)
            }
        }

        setLoadingSchedule(false)
    }

    const openSchedule = edit_id => {
        setModal({
            type:"process-schedule", 
            process_id: id,
            id: edit_id,
            success: () => initSchedule()
        })
    }

    const removeSchedule = async edit_id => {
        setLoadingSchedule(true)
        const result = await Delete(edit_id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido com sucesso");
            initSchedule();
        }
        setLoadingSchedule(false)
    }

    const loadFontAsBinary = async  (url, fontName) => {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const binaryString = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
        return binaryString
    }

    const saveFinalReport = () => {
        saveAsPDF("printableReport", `${register?.company_name} - Relatório final`)
    }

    const saveLaud = () => {
        saveAsPDF("printableCreditor", `${register?.company_name} - Lista de credores credenciados`)
        saveAsPDF("printableVotes", `${register?.company_name} - Pautas e votação`)
        saveAsPDF("printableReport", `${register?.company_name} - Resultado das votações por pauta`)
    }

    const saveAsPDF = (target, fname) => {
        setOpenToPrint(true)
        setTimeout(() => {
            const divWrapper = document.getElementById('global-wrapper');
            const div = document.getElementById(target);
            // const divW = document.getElementById("wrapper-print");
            
            // div.style.fontSize = "48px !important;"
            
            const divHeight = div.offsetHeight
            const divWidth = div.offsetWidth

            toPng(div)
                .then( (dataUrl) => { 
                    const img = new Image();
                    img.src = dataUrl;
                    img.onload = async () => {
                        const canvasDiv = document.getElementById("reportCanvas");
                        const canvas = document.createElement("canvas");
                        
                        canvas.id = "canvasPrint"
                        canvas.width = divWidth
                        canvas.height = divHeight
                        const ctx = canvas.getContext("2d");

                        canvasDiv.append(canvas)

                        // ctx.clearRect(0, 0, canvas.width, canvas.height);
                        ctx.drawImage(img, 0, 0, divWidth, divHeight);
                        const pdf = new jsPDF({
                            orientation: "landscape",
                            // unit: "in",
                            format: [divWidth, divHeight]
                        });

                        const myFont = await loadFontAsBinary('https://fonts.googleapis.com/css2?family=Poppins&display=swap', 'Poppins')

                        pdf.addFileToVFS("Poppins.ttf", myFont);
                        pdf.addFont("Poppins.ttf", "Poppins", "normal");
                        pdf.setFont("Poppins");

                        pdf.addImage(canvas.toDataURL(), 'PNG', 0, 0, divWidth, divHeight);
                        pdf.save(`${fname}.pdf`);
                    };

                    setTimeout(() => {
                        const canvasPrintDiv = document.getElementById("canvasPrint");
                        canvasPrintDiv.parentNode.removeChild(canvasPrintDiv)
                        // div.style.fontSize = "14px;"
                        setOpenToPrint(false)
                    }, 1)

                }).catch((error) => {
                    console.error('Error:', error);
                });
        }, 100  )
    }

    useEffect( () => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated page={1}> 
                <Row>
                    <Col> 

                        <HeaderRow>
                            <HeaderBack onClick={history.goBack} />
                            <HeaderContent>
                                <DashboardText> Assembléia geral de credores - { moment(register?.date)?.format('L') } Número do processo: { register?.process_number } </DashboardText> 
                                <DashboardTitle> { register?.company_name } </DashboardTitle> 
                            </HeaderContent>
                            
                            <HeaderActions>
                                {
                                    activeTab === 0 ? <>
                                        <LabelCredential>
                                            <LabelCredentialText>Credenciamento</LabelCredentialText>
                                            <Toggle checked={register?.in_accreditation} label={ register?.in_accreditation ? "Sim" : "Não"} onChange={() => toggleOpenAccreditation()} />
                                        </LabelCredential>
                                        <HeaderActionItem>
                                            {
                                                register?.status !== "scheduled" ? null : 
                                                    <Button nospace outline secondary loading={starting} onClick={startAssemble}>
                                                        <ButtonButtonIconPlay />
                                                        Iniciar assembleia
                                                    </Button>
                                            }
                                            {
                                                register?.status !== "running" ? null : 
                                                    <Button nospace outline secondary loading={starting} onClick={completeAssemble}>
                                                        Finalizar assembleia
                                                    </Button>
                                            }
                                            {/* {
                                                register?.status !== "finished" ? null : 
                                                    <Button nospace outline secondary loading={starting} onClick={scheduleAssemble}>
                                                        Reabrir assembleia
                                                    </Button>
                                            } */}
                                        </HeaderActionItem>
                                    </> : null
                                }
                                {
                                    activeTab === 1 ? <>
                                        {
                                            register?.status !== "scheduled" ? null : 
                                                <Button nospace outline secondary loading={starting} onClick={startAssemble}>
                                                    <ButtonButtonIconPlay />
                                                    Iniciar assembleia
                                                </Button>
                                        }
                                        {
                                            register?.status !== "running" ? null : 
                                                <Button nospace outline secondary loading={starting} onClick={completeAssemble}>
                                                    Finalizar assembleia
                                                </Button>
                                        }
                                    </> : null
                                }
                                {
                                    activeTab === 2 ? <>
                                        <RowOptions>
                                            <Button nospace outline primary onClick={saveLaud}>
                                                <ButtonButtonIconPdf />
                                                Exportar Laudo
                                            </Button>
                                            <Button nospace outline primary onClick={saveFinalReport}>
                                                <ButtonButtonIconPdf />
                                                Visualizar PDF
                                            </Button>
                                        </RowOptions>
                                    </> : null
                                }
                                
                            </HeaderActions>
                        </HeaderRow>

                        <ContentTabs>
                            {
                                tabs?.map((item, key) => 
                                    <TabItem active={key === activeTab} key={key} onClick={() => setActiveTab(key)}>
                                        <TabItemText>{ item?.title }</TabItemText>
                                    </TabItem>
                                )
                            }
                        </ContentTabs>

                        {
                            activeTab === 2 || openToPrint ? <>
                                <DashboardTitle>Resultado das votações por pauta</DashboardTitle>

                                {
                                    loadingSchedule ? <>
                                        <LoadCenter>
                                            <Load />
                                        </LoadCenter>
                                    </> : <div id="printableReport" style={{ position: 'relative', background: '#fff', display: openToPrint ? 'inline-block' : 'initial' }}>
                                        {
                                            votationSchedules?.map((m, k) => 
                                                <RowVoteResult key={k} title={m?.title} item={m} completed={m?.status === "finished"} reload={initSchedule} edit={() => openSchedule(m?.id)} remove={() => removeSchedule(m?.id)} />
                                            )
                                        }
                                    </div>
                                }

                            </> : null
                        }

                        {
                            true  ? <>
                                <ContentCardTable opened={activeTab === 1 || openToPrint}>
                                    <RowBetween>
                                        <DashboardTitle>Pautas e votação</DashboardTitle>
                                        <RowBetweenItem>
                                            <Button nospace secondary outline onClick={() => openSchedule()}>
                                                <ItemIcon icon={"plus"} />
                                                Nova pauta
                                            </Button>
                                        </RowBetweenItem>
                                    </RowBetween>
                                    <div id="printableVotes" style={{ position: 'relative', background: '#fff', display: openToPrint ? 'inline-block' : 'initial' }}>
                                        {
                                            loadingSchedule ? <>
                                                <LoadCenter>
                                                    <Load />
                                                </LoadCenter>
                                            </> : votationSchedules?.map((m, k) => 
                                                <RowVote key={k} title={m?.title} item={m} completed={m?.status === "finished"} reload={initSchedule} edit={() => openSchedule(m?.id)} remove={() => removeSchedule(m?.id)} />
                                            )
                                        }
                                    </div>
                                </ContentCardTable>
                            </> : null
                        }

                        {
                            true ? <ContainerHideTab opened={activeTab === 0 || openToPrint}> 

                                <ContentCard>
                                    <ContentCardItem>
                                        <Input placeholder="Pesquise procurador ou credor" value={searchExpression} onChange={e => setSearchExpression(e.target.value)} /> 
                                    </ContentCardItem>
                                    <ContentCardItem medium>
                                        <Select placeholder="Classificação" options={optionsClassification} value={formValue('classification')} onChange={e => changeForm(e, 'classification')} param={"title"} /> 
                                    </ContentCardItem>
                                    {/* <ContentCardItem small>
                                        <Select placeholder="Status" options={optionsStatus} value={formValue('status')} onChange={e => changeForm(e, 'status')} param={"title"} /> 
                                    </ContentCardItem> */}
                                    <ContentCardItem small>
                                        <Select placeholder="Status" options={optionsCreditorStatus} value={formValue('enabled')} onChange={e => changeForm(e, 'enabled')} param={"title"} /> 
                                    </ContentCardItem>
                                    <ContentCardItem small>
                                        <Select placeholder="Presença" options={optionsBoolean} value={formValue('presence')} onChange={e => changeForm(e, 'presence')} param={"title"} /> 
                                    </ContentCardItem>
                                    <ContentCardItem small>
                                        <Select placeholder="Votos" options={optionsBoolean} value={formValue('voted')} onChange={e => changeForm(e, 'voted')} param={"title"} /> 
                                    </ContentCardItem>
                                    
                                    <ContentCardActions>
                                        <Button nospace primary link onClick={clearFilter}>Limpar</Button>
                                        <Button nospace primary onClick={applyFilter}>Filtrar</Button>
                                    </ContentCardActions>

                                </ContentCard>

                                <div id="printableCreditor" style={{ position: 'relative', background: '#fff', display: openToPrint ? 'inline-block' : 'initial' }}>
                                    <ContentCardTable opened>
                                        <RowBetween>
                                            <DashboardTitle> Lista de credores credenciados </DashboardTitle> 
                                            {
                                                openToPrint ? null :
                                                <RowBetweenItem>
                                                    {
                                                        massing ? <Load /> :
                                                        <Select placeholder="Ações em massa" options={optionsAssembleMassActions} value={formValue('mass')} onChange={massAction} param={"title"} /> 
                                                    }
                                                </RowBetweenItem>
                                            }
                                        </RowBetween>
                                        <BasicTable loading={loading} columns={columns} rows={rows?.filter(filterExpression)?.filter(filterFilters)} selectable={setSelected} />
                                    </ContentCardTable>
                                </div>

                            </ContainerHideTab> : null
                        }

                    </Col> 
                </Row>
            </ContainerAuthenticated> 
            <div id="reportCanvas"></div>
        </>
    );
}