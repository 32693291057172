import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'
import { statusColors } from 'ui/theme-color';

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 16px;
    
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.primary };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;

export const ContentCard = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundcard };

    display: flex;
    gap: 12px;
    div{
        width: 100%;
        max-width: 200px;
    }
`;

export const ContentCardTable = styled.div.attrs({ 
})`
    padding: 32px 16px 16px;
    border-radius: 4px;
    background: ${ props => props.theme.palette.colors.backgroundgrey };
    margin: 12px 0 0;
`;

export const ContentCardTableAction = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 120px;
`;












export const StepsContainer = styled.div.attrs({ 
})`
    border-left: 1px solid  ${ p => p.theme.palette.colors.opaqueprimary };
    margin: 20px 0 20px 40px;
`;
export const StepItem = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 0 20px -20px;

    display: flex;

    cursor: pointer;

    ${
        p => p.future ? `
            color: ${ p.theme.palette.colors.opaqueprimary };
        ` : `
            font-weight: bold;
            color: ${ p.theme.palette.colors.black };
        `
    }
`;
export const StepItemCircle = styled.div.attrs({ 
})`
    width: 40px;
    height: 40px;
    border-radius: 20px;

    font-size: 24px;

    background: ${ p => p.theme.palette.colors.primary };
    border: 1px solid  ${ p => p.theme.palette.colors.primary };
    color: ${ p => p.theme.palette.colors.white };

    display: flex;
    justify-content: center;
    align-items: center;

    ${
        p => p.active ? `
            background: ${ p.theme.palette.colors.green };
            border: 1px solid  ${ p.theme.palette.colors.green };
            color: ${ p.theme.palette.colors.white };
        ` : ``
    }

    ${
        p => p.future ? `
            background: ${ p.theme.palette.colors.white };
            border: 1px solid  ${ p.theme.palette.colors.opaqueprimary };
            color: ${ p.theme.palette.colors.opaqueprimary };
        ` : ``
    }
`;

export const StepItemCircleDecoration = styled.img.attrs({ 
    src:'/icons/check.svg'
})`
`;

export const FormSpacer = styled.div.attrs({ 
})`
    margin-top: 18px;
`;

export const FormSuccessIcon = styled.img.attrs({ 
    src:'/icons/circle-success.svg'
})`
    margin: 0 auto;
`;


export const FormSuccessIconLogo = styled.img.attrs({ 
    src:'/icons/logo-icon.svg'
})`
    margin: 0 auto;
`;






export const DashboardInfo = styled.div.attrs({ 
})`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 12px;
    margin: 12px 0;
    border-radius: 4px;
    background: ${ p => p.theme.palette.colors.white };
`;
export const DashboardInfoItem = styled.div.attrs({ 
})`
    width: calc(50% - 8px);
`;
export const DashboardInfoItemTitle = styled.div.attrs({ 
})`
    color: ${ p => p.theme.palette.colors.primary };
    font-weight: 600;
    font-size: 14px;
`;
export const DashboardInfoItemText = styled.div.attrs({ 
})`
    color: ${ p => p.theme.palette.colors.text };
    font-size: 14px;
`;






export const DashboardOption = styled.div.attrs({ 
})`
    display: flex;
    justify-content: space-between;
    background: ${ p => p.theme.palette.colors.white };

    padding: 16px;
    border-radius: 4px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }
`;

export const DashboardOptionAction = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const DashboardOptionIcon = styled.img.attrs({ 
    src: '/icons/chevron.svg'
})`
    transform: rotate(270deg);
`;






export const CardCred = styled.div.attrs({ 
})`
    background: ${ p => p.theme.palette.colors.white };
    margin: 12px 0 0;
`;
export const CardCredHeader = styled.div.attrs({ 
})`
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const RemoveLink = styled.div.attrs({ 
})`
    cursor: pointer;
    color: ${ p => p.theme.palette.colors.red };
    &:hover{
        text-decoration: underline;
    }
`;
export const CardCredBody = styled.div.attrs({ 
})`
    padding: 0 12px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;



export const FormItem = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 180px;
`;

export const CardCredFooter = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    padding: 12px;
`;

export const CardCredFooterItem = styled.div.attrs({ 
})`
    padding: 4px 12px;
    border: 1px solid ${ p => p.theme.palette.colors?.[statusColors[p.status]] };
    flex: 1;
    border-radius: 8px;
    text-align: center;

    font-size: 14px;
    color: ${ p => p.theme.palette.colors.text };

    ${
        p => p.active ? `
            background: ${ p.theme.palette.colors?.[statusColors[p.status]] };
            color: ${ p.theme.palette.colors.white };
        ` : ``
    }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;