import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentCard,
    ContentCardTable,
    ContentCardTableAction
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import { optionsClassification, optionsEdictalStatus, optionsStatus } from "utils/options";
import BasicTable from "components/Form/Table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RowBetween } from "ui/styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { Read } from "services/processes";
import { ReadByUser as ReadUserProcess } from "services/process-users";
import { Read as ReadProcess } from "services/pre-users";
import { normalizeStrapiList, normalizeStrapiRegister } from "utils";
import moment from "moment/moment";
import { clearVotationId, parseCurrency } from "utils/parsers";

export default function DashboardHome(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { user } = useContext(CoreContext)

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ loading, setLoading ] = useState(false)

    const [rows, setRows] = useState([])

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 
    
    const columns = useMemo(() => {
        return user?.type !== "admin" ? 
        [
            { title:'Empresas', ref:'company_name' },
            { title:'Número do processo', ref:'number_process' },
            { title:'Status', ref:'status' },
            { title:'Data Assembleia', ref:'date' },
            { title:'Valor R$', ref:'value' },     
        ] : [
            { title:'Empresas', ref:'company_name' },
            { title:'Número do processo', ref:'number_process' },
            { title:'Administrador judicial ', ref:'judicial_administrator' },
            { title:'Edital', ref:'notice' },
            { title:'Número de credores', ref:'total_creditor' },
            { title:'Status', ref:'status' },
            { title:'Data Assembleia', ref:'date' },
            { title:'Valor R$', ref:'value' },     
        ] 
    }, [user])
    

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const filterStatus = item => {
        return ( !form?.status || item?.status === optionsEdictalStatus?.find(f => `${f.id}` === `${form?.status}` )?.title )
    }

    const parseContract = async (m) => {
        const users = await ReadProcess(m.id)
        return {
            ...m,
            total_creditor: users?.data?.length || 0,
            date: moment(m?.datetime).format("L"),
            status: optionsEdictalStatus?.find(f => `${f.ref}` === `${m?.status}` )?.title,
            notice: optionsStatus?.find(f => `${f.ref}` === `${m?.notice}` )?.title,
            value: !m?.value ? "" : parseCurrency(m?.value),
            action: () => navigate(`dashboard/process/create/${ m?.id }`)
        }
    }

    const init = async () => {
        if(user?.id){
            setLoading(true)

            if(user?.type === "admin"){
                const result = await Read(user?.id)
                if(result?.data?.length){ 
                    const promises = (normalizeStrapiList(result) || []).map(parseContract)
                    const normalResult = await Promise.all(promises)
                    setRows(normalResult)
                }
            }

            if( user?.type !== "admin" ){
                const result = await ReadUserProcess(user?.id)
                if(result?.data?.length){ 

                    const normalResult = (normalizeStrapiList(result) || [])
                        .map(m => ({ 
                            ...m, 
                            process: normalizeStrapiRegister(m?.process), 
                            user_creditor: normalizeStrapiRegister(m?.user_creditor) 
                        }))
                        .map(m => ({
                            ...m,
                            ...m?.process, 
                            date: moment(m?.process?.datetime).format("L"),
                            status: optionsEdictalStatus?.find(f => `${f.ref}` === `${m?.process?.status}` )?.title,
                            notice: optionsStatus?.find(f => `${f.ref}` === `${m?.process?.notice}` )?.title,
                            value: !m?.process?.value ? "" : parseCurrency(m?.process?.value),
                            action: () => navigate(`dashboard/vote/${ clearVotationId(m?.process?.link) }`)
                        }))
                    setRows(normalResult)
                    
                    console.log("normalResult", normalResult)

                }
            }   


            setLoading(false)
        }

        console.log("user #1", user)
    }

    useEffect(() => { init() ;}, [user])

    return ( 
        <>
            <ContainerAuthenticated noside={!(user?.type === "admin")}>
                <Row>
                    <Col> 
                        {
                            !(user?.type === "admin") ? <>
                                <DashboardTitle> Acompanhe as assembleias dos processos  </DashboardTitle> 
                            </> : <>
                                <RowBetween>
                                    <DashboardTitle> Processos </DashboardTitle> 
                                    <ContentCardTableAction>
                                        <Button primary onClick={() => navigate("dashboard/process/create")}>Novo</Button>
                                    </ContentCardTableAction>
                                </RowBetween>
                                
                                <ContentCard>
                                    <div>
                                        <Input placeholder="Pesquise por empresa" value={searchExpression} onChange={e => setSearchExpression(e.target.value)} /> 
                                    </div>
                                    <div>
                                        <Select placeholder="Status" options={optionsEdictalStatus} value={formValue('status')} onChange={e => changeForm(e, 'status')} /> 
                                    </div>
                                </ContentCard>
                            </>
                        }

                        <ContentCardTable>
                            { !(user?.type === "admin") ? <DashboardTitle> Assembleias </DashboardTitle> : <DashboardTitle> Lista de processos </DashboardTitle> }
                            <BasicTable loading={loading} columns={columns} rows={rows?.filter(filterExpression)?.filter(filterStatus)} />
                        </ContentCardTable>
                        
                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}