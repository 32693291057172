import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#4B8551', 
      primary: '#4B8551', 
      lightprimary:"#edf3ee",
      lightgreen:"#edf3ee",
      white: '#fff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#49C145',
    },
    error: {
      main: '#dd4952',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#66bb6a',
    },

    red: {
      main: '#ff035c', 
      primary: '#ff035c', 
      lightprimary:"#eed5de",
      lightgreen:"#edf3ee",
      contrastText: '#fff',
      white: '#fff',
    },

    green: {
      main: '#4B8551', 
      primary: '#4B8551', 
      lightprimary:"#edf3ee",
      lightgreen:"#edf3ee",
      white: '#fff',
      contrastText: '#fff',
    },

    blue: {
      main: '#4c56a3', 
      primary: '#4c56a3', 
      lightprimary:"#dddee5",
      lightgreen:"#edf3ee",
      white: '#fff',
      contrastText: '#fff',
    },

    colors:{
        white: '#ffffff',
        black: '#000000',
        text: '#333333',
        grey: '#4C4C4C',
        background: '#cccccc',
        backgroundcard: '#ededed',
        backgroundgrey: '#F7F7F7',
        lightgrey: '#A4A4A4',
        shadow: 'rgba(0,0,0,.16)',
        lightshadow: 'rgba(112,112,112,.06)',

        red:"#ff005c",
        orange:"#ff9900",
        green:"#00ba54",
        lightgreen:"#edf3ee",
        
        
        primary: '#4B8551', 
        lightprimary:"#edf3ee",
        opaqueprimary:"#A4C1A7",
        cyan: '#00E5C3', 

    }
  },
});  

export const statusColors = {
  "Abstenção": "orange",
  "Reprovado": "red",
  "Aprovado": "green",
  "Abster": "orange",
  "Reprovar": "red",
  "Aprovar": "green",
}