import { GET, POST, PUT, DELETE } from './api'

const table = "process-users"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async (id) => {
    return await GET(`/${ table }?populate[0]=user_creditor.user_prosecutor&populate[1]=represented_users.user_creditor&populate[2]=process.user&filters[process]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadByUser = async (id) => {
    return await GET(`/${ table }?populate[0]=user_creditor.user_prosecutor&populate[1]=represented_users.user_creditor&populate[2]=process.user&filters[user_creditor]=${id}&_limit=-1&pagination[pageSize]=1000`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
} 
 