import React, { useCallback, useContext, useEffect, useState } from "react";  
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {  
    DashboardTitle,
    ContentCard,
    ContentCardTable,
    ContentCardItem
} from "./styled";

import { Row, Col } from "reactstrap";

import { optionsClassification, optionsCreditorStatus, optionsEdictalStatus, optionsStatus } from "utils/options";

import ContainerAuthenticated from "containers/Authenticated";
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import BasicTable from "components/Form/Table";
import { Read } from "services/processes";
import { normalizeStrapiList } from "utils";
import { parseCurrency } from "utils/parsers";
import moment from "moment/moment";
import { CoreContext } from "context/CoreContext";
import { ReadByUser as ReadUserProcess } from "services/process-users";
import { Read as ReadProcess } from "services/pre-users";

export default function DashboardAssemblies(){  

    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { user } = useContext(CoreContext)

    const [ searchExpression, setSearchExpression ] = useState("")
    const [ loading, setLoading ] = useState(false)

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 
    
    const columns = [
        { title:'Empresas', ref:'company_name' },
        { title:'Número do processo', ref:'number_process' },
        { title:'Número de credores', ref:'total_creditor' },
        { title:'Status', ref:'status' },
        { title:'Data Assembleia', ref:'date' },
        { title:'Valor R$', ref:'value' }
    ] 

    const [rows, setRows] = useState([])

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item).filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    const filterStatus = item => {
        return ( !form?.status || item?.status === optionsEdictalStatus?.find(f => `${f.id}` === `${form?.status}` )?.title )
    }

    const parseAssembly = async m => {
        const users = await ReadProcess(m.id)
        return ({
            ...m,
            total_creditor: users?.data?.length || 0,
            date: moment(m?.datetime).format("L"),
            status: optionsEdictalStatus?.find(f => `${f.ref}` === `${m?.status}` )?.title,
            value: !m?.value ? "" : parseCurrency(m?.value),
            action:() => navigate(`dashboard/assembly/details/${ m?.id }`) 
        })
    }

    const init = useCallback(async () => {
        if(user?.id){
            setLoading(true)
            const result = await Read(user?.id)
            setLoading(false)
            
            if(result?.data?.length){ 
                const promises = (normalizeStrapiList(result) || []).map(parseAssembly)
                const normalResult = await Promise.all(promises)
                setRows(normalResult)
            }
        }
    }, [user])

    useEffect(() => { init() ;}, [init])

    return ( 
        <>
            <ContainerAuthenticated page={1} noside={!(user?.type === "admin")}>
                <Row>
                    <Col> 
                        <DashboardTitle> Assembleia </DashboardTitle> 
                        
                        <ContentCard>
                            <ContentCardItem>
                                <Input placeholder="Pesquise por processo ou empresa" value={searchExpression} onChange={e => setSearchExpression(e.target.value)} /> 
                            </ContentCardItem>
                            <ContentCardItem>
                                <Select placeholder="Status" options={optionsEdictalStatus} value={formValue('status')} onChange={e => changeForm(e, 'status')} param={"title"} /> 
                            </ContentCardItem>
                        </ContentCard>

                        <ContentCardTable>
                            <DashboardTitle> Lista de assembleias </DashboardTitle> 
                            <BasicTable loading={loading} columns={columns} rows={rows?.filter(filterExpression)?.filter(filterStatus)} />
                        </ContentCardTable>

                    </Col> 
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}