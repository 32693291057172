import styled from 'styled-components' 
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
}  

export const Touch = styled.div.attrs({ 
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px ${ props => props.theme.palette.colors.shadow };
    }
`;

export const Load = styled(ReactLoading).attrs(p => ({ 
    type:'spin',
    color: p.theme.palette.primary.main,
    height:20,
    width:20
}))`
    ${
        p => p.centred ? `
            margin: 0 auto;
        ` : ``
    }
`;
 
export const Animation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    }, 
    width: props.width ? props.width : 320
}))`        
    max-width: 100%;
`;  

export const EmptyMessage = styled.div.attrs({ 
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.black };
`;

export const LoadCenter = styled.div.attrs({ 
})`         
    width: 20px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;

export const DecoratedScroll = styled.div.attrs({ 
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${ props => props.theme.palette.secondary.main };
        outline: 0 solid ${ props => props.theme.palette.secondary.main };
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;

export const RowBetween = styled.div.attrs({ 
})`         
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
    flex-wrap: wrap;
`;

export const RowBetweenItem = styled.div.attrs({ 
})`         
    width: 100%;
    max-width: 300px;
`;

export const ItemIcon = styled.img.attrs(p => ({
    src: `/icons/${ p.icon }.svg`
}))`
    margin: -2px 5px 0 0 ;
`;


export const WhiteBg = styled.div.attrs({ 
})`
    background: ${ props => props.theme.palette.colors.white };
    display: flex;
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 3px;
`;

export const CheckContainer = styled.div.attrs({ 
})`
    margin-top: -11px;

    width: 30px;
    height: 30px;

`;


export const ResponsibleTable = styled.div.attrs({ 
})`

    overflow-x: auto;
    // display: grid;
    width: 100%;



    // ::-webkit-scrollbar-thumb {
    //     border-radius: 4px;
    //     background-color: ${ props => props.theme.palette.primary.main };
    //     outline: 0 solid ${ props => props.theme.palette.primary.main };
    // }

    // ::-webkit-scrollbar {
    //     width: 1px;
    //     height: .2rem;
    // }

    // ::-webkit-scrollbar-track {  
    // }




    
`;


export const ResponsibleTableContent = styled.div.attrs({ 
})`
    min-width: fit-content;
`;

export const MobileContent = styled.div.attrs({ 
})`
    display: none;
    @media(max-width: 991px){
        display: block;
    }
`;
