import styled from 'styled-components'  
 
export const DashboardPage = styled.div.attrs({ 
})`            
    background: ${ props => props.theme.palette.colors.white };
    
    flex: 1;

    ${
        p => p.noside ? `
        ` : `
            max-width: calc( 100% - 252px );
        
            @media(max-width: 991px){
                max-width: calc( 100% - 0px ) ;
            }
        `
    }
`;

export const DashboardBody = styled.div.attrs({ 
})`       
    min-height: calc(100vh - 60px);
    background: ${ props => props.theme.palette.colors.backgroundcard };

    display: flex;
    flex-direction: column;
    align-items: flex-start;     
    overflow:auto;
    
    width: 100%; 
    max-width: calc( 100% - 0px );

    @media(max-width: 991px){
        max-width: calc( 100% - 0px ) ;
    }
`;

export const DashboardBodyContent = styled.div.attrs({ 
})`            
    margin: 24px;
    background: ${ props => props.theme.palette.colors.white };
    padding: 35px 20px;
    width: 100%;
    max-width: calc( 100% - 48px );
    border-radius: 4px;
    min-height: calc(100vh - 108px);
`;

export const Content = styled.div.attrs({ 
})`           
    overflow:hidden;
    display: flex;
    justify-content: flex-start; 
`; 